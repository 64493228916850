
const calculateTotalAmount = (rows, discount) => {
    const total = rows.reduce((sum, row) => {
        const rowPrice = parseFloat(Number(row.price) || 0);
        const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
        return sum + rowPrice + rowTax;
    }, 0);

    const discountAmount = (total * (parseFloat(discount) || 0)) / 100;

    return total - discountAmount;
};

const calculateTotal = (rows) => {
    const total = rows.reduce((sum, row) => {
        const rowPrice = parseFloat(Number(row.price) || 0);
        return sum + rowPrice;
    }, 0);


    return total;
};

const calculateDiscountAmount = (rows, discount) => {
    const total = rows.reduce((sum, row) => {
        const rowPrice = parseFloat(Number(row.price) || 0);
        const rowTax = row.tax ? (rowPrice * (parseFloat(row.tax) / 100)) : 0;
        return sum + rowPrice + rowTax;
    }, 0);

    const discountAmount = (total * (parseFloat(discount) || 0)) / 100;

    return discountAmount;
};

const calculateTotalTaxAmount = (items) => {
    // Calculate the total amount considering item-specific taxes
    const totalAmount = items.reduce((acc, item) => {
        const itemTotal = item.price * (item.tax / 100);
        return acc + itemTotal;
    }, 0);

    return totalAmount;
};

export const printFormData = (data) => {
    const printWindow = window.open('', '_blank');
    const total = calculateTotal(data.items);
    const totalAmount = calculateTotalAmount(data.items, data.discount);
    const totalDiscount = calculateDiscountAmount(data.items, data.discount);
    const taxamount = calculateTotalTaxAmount(data.items);
    const payAmount = parseFloat(data.payamount || 0);
    const payDue = totalAmount - payAmount;
    const status = data.term === 'due on receipt' ? 'PAID' : totalAmount > payAmount ? 'PENDING' : 'Unpaid'

    const htmlContent = `

    <html >
    <head>
        <title>Invoice</title>
    </head>
    <body style="font-family: Arial, sans-serif; margin: 10px; padding: 20px; background-color: #f8f9fa;">
        <div style=" margin: 0 auto; background-color: #fff; padding: 30px; border-radius: 8px; ;">
            <!-- Header Section -->
            <div style="text-align: center; margin-bottom: 30px;">
                <img src=${`https://admin.bizstor.lk/storage/app/public/images/${data.image}`} alt="Company Logo" style="max-width: 100px; margin-bottom: 15px;">
                <h1 style="margin: 0; font-size: 26px; color: #2c3e50; font-weight: 600; text-transform: uppercase">${data.company}</h1>
                <p style="margin: 5px 0; font-size: 14px; color: #7f8c8d;">${data.address}</p>
                <p style="margin: 5px 0; font-size: 14px; color: #7f8c8d;">Phone: ${data.phone} | Email: ${data.uemail} | Web: ${data.web}</p>
            </div>
    
            <!-- Customer and Invoice Details -->
            <div style="display: flex; justify-content: space-between; margin-bottom: 30px;">
                <!-- Customer Details -->
                <div style="flex: 1;">
                    <h2 style="font-size: 18px; color: #34495e; margin-bottom: 10px; font-weight: 500;">Bill To:</h2>
                    <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Name:</strong>${data.fullname}</p>
                    <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Email:</strong> ${data.email}</p>
                    <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Address:</strong> ${data.address}</p>
                </div>
                <!-- Invoice Details -->
                <div style="flex: 1; text-align: right;">
                    <h2 style="font-size: 18px; color: #34495e; margin-bottom: 10px; font-weight: 500;">Invoice Details</h2>
                    <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Invoice No:</strong>#${data.invoiceNo}</p>
                    <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Date:</strong>${data.invoicedate}</p>
                    <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>status:</strong>${status}</p>
                </div>
            </div>
            
    
            <!-- Order Details Table -->
            <table id="invoice-table" style="width: 100%; border-collapse: collapse; margin-bottom: 30px;">
                <thead>
                    <tr style="background-color: #34495e; color: #fff;">
                        <th style="padding: 12px; border: 1px solid #ddd; text-align: left; color: #34495e;">Product</th>
                        <th style="padding: 12px; border: 1px solid #ddd; text-align: left; color: #34495e;">Quantity</th>
                        <th style="padding: 12px; border: 1px solid #ddd; text-align: left; color: #34495e;">Rate</th>
                        <th style="padding: 12px; border: 1px solid #ddd; text-align: left; color: #34495e;">Amount</th>
                        <th style="padding: 12px; border: 1px solid #ddd; text-align: left; color: #34495e;">Tax</th>
                    </tr>
                </thead>
                <tbody>
                ${data.items.map((item, index) => `
                    <tr>
                        <td style="padding: 12px; border: 1px solid #ddd;">${item.product}</td>
                        <td style="padding: 12px; border: 1px solid #ddd;">${item.quantity}</td>
                        <td style="padding: 12px; border: 1px solid #ddd;">${Number(item.rate).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td style="padding: 12px; border: 1px solid #ddd;">${Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td style="padding: 12px; border: 1px solid #ddd;">${item.tax}%</td>
                        </tr>
                 `).join('')}
                </tbody>
            </table>
    
            <!-- Total Calculation -->
            <div style="text-align: right; background-color: #f8f9fa; padding: 20px; border-radius: 6px; margin-bottom: 20px;">
                <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Subtotal:</strong>Lkr.${(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Tax:</strong>Lkr.${(taxamount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Discount:</strong> Lkr.${(totalDiscount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                <p style="margin: 5px 0; font-size: 18px; color: #2c3e50; font-weight: 600;"><strong>Grand Total:</strong> Lkr.${(totalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            </div>
    
            <!-- Payment Details -->
            ${data.term !== 'due on receipt' ? `
            <div style="background-color: #f8f9fa; padding: 20px; border-radius: 6px; margin-bottom: 20px;">
                <h2 style="font-size: 18px; color: #34495e; margin-bottom: 10px; font-weight: 500;">Payment Details</h2>
                <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Payment:</strong> Lkr.${(payAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                <p style="margin: 5px 0; font-size: 14px; color: #555;"><strong>Payable:</strong> Lkr.${(payDue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
            </div>
    ` : ''}
            <!-- Footer -->
            <div style="text-align: center; margin-top: 30px; font-size: 12px; color: #7f8c8d;">
                <p>Thank you for your business!</p>
                <p>If you have any questions, please contact us at ${data.phone}.</p>
            </div>
        </div>
    </body>
    </html>    
    `;

    printWindow.document.write(htmlContent);
    printWindow.document.close();

    setTimeout(() => {
        printWindow.print();
    }, 1000);
};
