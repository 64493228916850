import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";

const ProfitAndLoss = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [orders, setOrders] = useState([]);
    const [damages, setDamages] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [fetchedOrderData, setFetchedOrderData] = useState([]);
    const [totalAmountDueOnReceipt, setTotalAmountDueOnReceipt] = useState(0);
    const [fetchedAllPaymentData, setFetchedAllPaymentData] = useState([]);
    const [totalAllPayment, setTotalAllPayment] = useState(0);
    const [fetchedPaymentData, setFetchedPaymentData] = useState([]);
    const [totalPayment, setTotalPayment] = useState(0);
    const [combinedTotal, setCombinedTotal] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    {/**useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, [token]);*/}

    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchData();
            fetchOrderData();
            fetchPayments();
            fetchOrderPaymentData();
        }
    }, [user.id]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const ordersResponse = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            const filteredOrders = ordersResponse.data.filter(order =>
                order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
            );
            setOrders(filteredOrders);

            const damagesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            const filteredDamages = damagesResponse.data.filter(damage =>
                damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
            );
            setDamages(filteredDamages);

            calculateGrandTotal(filteredOrders, filteredDamages);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

    const calculateGrandTotal = (orders, damages) => {
        const groupedOrders = orders.reduce((acc, order) => {
            order.items.forEach(item => {
                const rate = item.rate || 0;
                const quantity = item.quantity || 0;
                const discount = order.discount || 0;
                const tax = item.tax || 0;

                const key = `${item.product}-${rate}`;
                if (!acc[key]) {
                    acc[key] = { totalPrice: 0, totalDiscount: 0, totalTax: 0, total: 0 };
                }

                acc[key].totalPrice += rate * quantity;
                acc[key].totalDiscount += (rate * quantity * discount) / 100;
                acc[key].totalTax += (rate * quantity * tax) / 100;
                acc[key].total = acc[key].totalPrice - acc[key].totalDiscount + acc[key].totalTax;
            });
            return acc;
        }, {});

        Object.values(groupedOrders).forEach(order => {
            const damageKey = `${order.product}-${order.rate}`;
            const damage = damages.find(d => `${d.product}-${d.rate}` === damageKey && d.type === "money back");
            if (damage) order.total -= damage.totalPrice;
        });

        const grandTotalValue = Object.values(groupedOrders).reduce(
            (totals, { total }) => totals + total, 0
        );

        setGrandTotal(grandTotalValue || 0);
    };

    const fetchOrderData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                const Order = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
                    order.term === 'due on receipt'
                );

                let total = 0;

                Order.forEach(order => {
                    let orderTotal = 0;

                    order.items.forEach(item => {
                        const itemPrice = item.price || 0;
                        const itemTax = item.tax || 0;
                        const itemTotal = itemPrice + (itemPrice * (itemTax / 100));
                        orderTotal += itemTotal;
                    });

                    const discountPercentage = order.discount || 0;
                    const discountAmount = (orderTotal * discountPercentage) / 100;
                    orderTotal -= discountAmount;

                    total += orderTotal;
                });

                setFetchedOrderData(Order);
                setTotalAmountDueOnReceipt(total);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchPayments = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-payment-data');
            if (user.id) {
                const Payment = response.data.filter(payment =>
                    payment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                let totalallpayment = 0;

                Payment.forEach(payment => {
                    totalallpayment += payment.amount || 0;
                });

                setFetchedAllPaymentData(Payment);
                setTotalAllPayment(totalallpayment);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchOrderPaymentData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                const Order = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
                    order.term !== 'due on receipt'
                );

                let totalpay = 0;

                Order.forEach(order => {
                    let orderTotal = 0;
                    orderTotal += order.payamount || 0;
                    totalpay += orderTotal;
                });

                setFetchedPaymentData(Order);
                setTotalPayment(totalpay);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        updateCombinedTotal();
    }, [totalAmountDueOnReceipt, totalAllPayment, totalPayment]);

    const updateCombinedTotal = () => {
        const combinedTotal = totalAmountDueOnReceipt + totalAllPayment + totalPayment;
        setCombinedTotal(combinedTotal);
    };

    const formatCurrency = (value) => {
        return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <>
            {loading ? (
                <>loading...</>
            ) : (
                <>Lkr.{formatCurrency(grandTotal - combinedTotal)}</>
            )}
        </>
    );
};

export default ProfitAndLoss;
