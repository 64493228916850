import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Switch ,  Navigate } from 'react-router-dom';
//import FormWithTable from './component/FormWithTable';
import Stock from './component/Stock/AllStock';
import Expenses from './component/Expences';
import Invoicetable from './component/Reports/Invoicetable';
import Stocktable from './component/Reports/Stocktable';
import Expensetable from './component/Reports/Expensestable';
import Customerreport from './component/Reports/CustomerReports';
import Home from './component/Home/Home';
import Monthlyincome from './component/Incomeandexpenses/Expenses';
import AllChart from './component/Chart/AllChart/AllChart';
import MakePayment from './component/Popupdialogbox/Makepayment';
import Test1 from './component/Tested/InvoiceTable';
import AddProduct from './component/Addform/AddProduct';
import AddCustomer from './component/Addform/AddCustomer';
import AddExpenses from './component/Addform/AddExpenses';
import AddSupplier from './component/Addform/AddSupplier';
import Test4 from './component/Tested/invoice';
//import Test5 from './component/Tested/invoiceupdate';
import Test6 from './component/Tested/producttest';
import Test7 from './component/Tested/expensestest';
import Test8 from './component/Stocksummary/stocksummary';
import Test10 from './component/SideDrawer/SideDrawer';
import Test11 from './component/Tested/addcustomer';
import Appbar from './component/AppbarAndDrawer/appbar';
import AllReport from './component/Reports/AllReports/AllReport';
import Invoicepopup from './component/Popupdialogbox/Invoice';
import AppWrapper from './component/AppbarAndDrawer/AppWrapper';
import ProtectedRoute from './component/Auth/ProtectedRoute';
//import Paymenttest2 from './component/Tested/Paymenttable';
import Stocktestedit from './component/Tested/reportstest/stock';
import Expensestestedit from './component/Tested/reportstest/expenses';
import Invoicetestedit from './component/Tested/reportstest/invoice';
import Returntest from './component/Tested/returntest';
import ReturnInvoice from './component/ReturnInvoice';
import Returntestedit from './component/Tested/reportstest/return';
import OrderHistory from './component/Reports/OrderHistory';
import Sum from './component/Reports/Summary';
import AllSum from './component/Reports/Alltest';
import Register2 from './component/Auth2/Register';
import Login2 from './component/Auth2/Login';
import Profile2 from './component/Auth2/Profile';
import Table from './component/Auth2/Table';
import ForgotPassword from './component/Auth2/ForgotPassword';
import ResetPassword from './component/Auth2/ResetPassword';
import InvoiceNew from './component/Home/home2';
import PrivateRoute from './component/Auth2/RoutesForAuth/PrivateRoute';
import PT from './component/Reports/paymenttest';
//import PR from './component/RoutessProtection/privateroute';
import Investment from './component/Investment';
import Items from './component/Stock/Item';
import BalanceSheet from './component/Reports/BalanceSheet';
import Users from './component/Admin/Users';
import AdminRoute from './component/Auth2/AdminRoute';
import Payments from './component/Admin/Payments';
import PaymentReports from './component/Admin/Reports';
import UserManagement from './component/Admin/UserManagement';
import NewProfile from './component/Auth2/Profilenew';
import  PublicRoute from './component/Auth2/RoutesForAuth/PublicRoute';
import Privacy from './Home/Page/privacy';
import { Snackbar, Alert } from '@mui/material';

const App = () => {
  //const [token, setToken] = useState(null);

  {/**useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);*/}

  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);




  let logoutTimer;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const resetTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      handleLogout();
    }, 20 * 60 * 1000); // 20 minutes
  };

  const handleLogout = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
  
    try {
      if (navigator.onLine) {
        await axios.post('https://admin.bizstor.lk/public/api/logout', null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        localStorage.setItem('logoutMessage', 'Logged out due to inactivity (20 minutes).');
      } else {
        localStorage.setItem('logoutMessage', 'No internet connection. Logged out due to inactivity.');
      }
    } catch (error) {
      console.error('Logout API request failed:', error);
      localStorage.setItem('logoutMessage', 'Logout failed. Logged out due to inactivity.');
    } finally {
      localStorage.removeItem('token');
      window.location.href = '/'; // Redirect to home page
    }
  };
  
  useEffect(() => {
    resetTimer();
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, []);
  
  
  return (
    <>
    <Router>
      <AppWrapper>
        <Routes>
          {/** <Route path="/" element={<Home />} />*/}
          {/** <Route path="/invoice" element={<FormWithTable />} />*/}
          {/** <Route path="/stock" element={<Stock />} />*/}
          {/**  <Route path="/return" element={<ReturnInvoice />} />*/}
          <Route path="/expenses" element={<PrivateRoute><Expenses /></PrivateRoute>} />
          <Route path="/investment" element={<PrivateRoute><Investment /></PrivateRoute>} />
          <Route path="/invoicetable" element={<PrivateRoute><Invoicetable /></PrivateRoute>} />
          <Route path="/stocktable" element={<PrivateRoute><Stocktable /></PrivateRoute>} />
          <Route path="/expensestable" element={<PrivateRoute><Expensetable /></PrivateRoute>} />
          <Route path="/customer-reports" element={<PrivateRoute><Customerreport /></PrivateRoute>} />
          <Route path="/orders/:fullname" element={<PrivateRoute><OrderHistory /></PrivateRoute>} />
          <Route path="/allreport" element={<PrivateRoute><AllReport /></PrivateRoute>} />
          <Route path="/chart" element={<PrivateRoute><AllChart /></PrivateRoute>} />
          <Route path="/data" element={<PrivateRoute><Monthlyincome /></PrivateRoute>} />
          <Route path="/makepayment" element={<PrivateRoute><MakePayment /></PrivateRoute>} />
          <Route path="/makepayment" element={<PrivateRoute><MakePayment /></PrivateRoute>} />
          <Route path="/test" element={<PrivateRoute><Test1 /></PrivateRoute>} />
          {/** <Route path="/addproduct" element={<AddProduct />} />*/}
          {/** <Route path="/addcustomer" element={<AddCustomer />} />*/}
          <Route path="/addexpenses" element={<PrivateRoute><AddExpenses /></PrivateRoute>} />
          {/** <Route path="/addsupplier" element={<AddSupplier />} />*/}
          <Route path="/test4" element={<PrivateRoute><Test4 /></PrivateRoute>} />
          {/** <Route path="/test5" element={<Test5 />} />*/}
          <Route path="/test6" element={<PrivateRoute><Test6 /></PrivateRoute>} />
          {/** <Route path="/test7" element={<Test7 />} />*/}
          <Route path="/inventory-details" element={<PrivateRoute><Test8 /></PrivateRoute>} />
          <Route path="/test10" element={<PrivateRoute><Test10 /></PrivateRoute>} />
          <Route path="/appbar" element={<PrivateRoute><Appbar /></PrivateRoute>} />
          <Route path="/ap" element={<PrivateRoute><Test11 /></PrivateRoute>} />
          <Route path="/invoicepopup" element={<PrivateRoute><Invoicepopup /></PrivateRoute>} />
          <Route path="/stocktestedit" element={<PrivateRoute><Stocktestedit /></PrivateRoute>} />
          <Route path="/expensestestedit" element={<PrivateRoute><Expensestestedit /></PrivateRoute>} />
          {/**  <Route path="/invoicetestedit" element={<Invoicetestedit />} />*/}
          <Route path="/returntestedit" element={<PrivateRoute><Returntestedit /></PrivateRoute>} />
          <Route path="/returntest" element={<PrivateRoute><Returntest /></PrivateRoute>} />
          <Route path="/sum" element={<PrivateRoute><Sum /></PrivateRoute>} />
          <Route path="/allsum" element={<PrivateRoute><AllSum /></PrivateRoute>} />
          {/** <Route path="/login" element={<ProtectedRoute><Login2 /></ProtectedRoute>} />*/}
          <Route path="/register" element={<PublicRoute><Register2 /></PublicRoute>} />
          <Route path="/" element={<PublicRoute><Login2 setToken={setToken} /></PublicRoute>} />
          {/** <Route path="/profile" element={<PrivateRoute><Profile2 token={token} setToken={setToken} /></PrivateRoute>} />*/}
          <Route path="/dashboard" element={<PrivateRoute><Home /></PrivateRoute>} />
          {/**  <Route path="/invoice" element={<PrivateRoute><Test5 token={token} setToken={setToken} /></PrivateRoute>} />*/} 
          <Route path="/invoicetestedit" element={<PrivateRoute><Invoicetestedit  /></PrivateRoute>} />          
          <Route path="/table" element={<Table  />} />
          <Route path="/test7" element={<PrivateRoute><Test7  /></PrivateRoute>} />
          <Route path="/return" element={<PrivateRoute><ReturnInvoice  /></PrivateRoute>} />
          <Route path="/inventory" element={<PrivateRoute><Stock  /></PrivateRoute>} />
          <Route path="/addproduct" element={<PrivateRoute><AddProduct  /></PrivateRoute>} />
          <Route path="/addcustomer" element={<PrivateRoute><AddCustomer  /></PrivateRoute>} />
          <Route path="/addsupplier" element={<PrivateRoute><AddSupplier  /></PrivateRoute>} />
          <Route path="/balancesheet" element={<PrivateRoute><BalanceSheet  /></PrivateRoute>} />
          {/*<Route path="/" element={<Navigate to={token ? "/profile2" : "/login2"} />} />*/}
          <Route path="/forgot-password" element={<ProtectedRoute><ForgotPassword /></ProtectedRoute>} />
          <Route path="/password-reset/:id" element={<ProtectedRoute><ResetPassword /></ProtectedRoute>} />
          <Route path="/invoice" element={<PrivateRoute><InvoiceNew /></PrivateRoute>} />
          <Route path="/paymenttest" element={<PrivateRoute><PT /></PrivateRoute>} />
          <Route path="/items" element={<PrivateRoute><Items /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><AdminRoute><Users /></AdminRoute></PrivateRoute>} />
          <Route path="/payments" element={<PrivateRoute><AdminRoute><Payments /></AdminRoute></PrivateRoute>} />
          <Route path="/payment-reports" element={<PrivateRoute><AdminRoute><PaymentReports /></AdminRoute></PrivateRoute>} />
          <Route path="/user-management" element={<PrivateRoute><AdminRoute><UserManagement /></AdminRoute></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><NewProfile /></PrivateRoute>} />
          <Route path="/privacy-policy" element={<><Privacy /></>} />

        </Routes>
      </AppWrapper>
    </Router>
  
    </>
  );
};

export default App;
