import React from 'react';
import { Box, Typography,  } from '@mui/material';
import { Link } from 'react-router-dom';


export default function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <Box
      sx={{
        display: {sm:'flex',xs:'none'},
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '10px 20px',
        backgroundColor: 'rgb(241, 241, 241)', // White background
        position: 'fixed',
        bottom: 0,
        left: 0,
        //borderTop: '1px solid #ddd', // Light border for separation
      }}
    >
      {/* Left Box (Empty) */}
      <Box sx={{ flex: 1 }} />

      {/* Middle Box */}
      <Box sx={{ flex: 1, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          © {currentYear} Bizstor. All rights reserved.
        </Typography>
      </Box>

      {/* Right Box (Links) */}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', gap: '5px' }}>
        <Link to={'/privacy-policy'}>
      <Typography variant="body2" color="textSecondary">privacy policy</Typography>
      </Link>
      </Box>
    </Box>
  );
}
