import BG from '../Auth2/bg.jpg';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  Snackbar,
  Alert, Divider, Stack
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import BG01 from './img01.jpg';
import Footer from '../../Home/Footer/Footer';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const pastelColors = [
  '#7B9E75', // Darker pastel green
  '#D9B800', // Darker pastel yellow
  '#F07A2B', // Darker pastel orange
  '#D74359', // Darker pastel pink
  '#9E4A84', // Darker pastel purple
  '#F085A0', // Darker pastel lavender
  '#F27A7A', // Darker pastel coral
  '#B6D1A1', // Darker pastel mint
];

const Login = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(0);

  const text1 = " Most efficient POS system for your business.";
  const text2 = "Manage everything seamlessly and effortlessly.";
  const [displayText1, setDisplayText1] = useState('');
  const [displayText2, setDisplayText2] = useState('');
  const [index, setIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const [textColor, setTextColor] = useState(pastelColors[0]);

  const typingSpeed = 100; // Speed for typing
  const backspacingSpeed = 50; // Speed for backspacing
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState('');


  useEffect(() => {
    const message = localStorage.getItem('logoutMessage');
    if (message) {
      setLogoutMessage(message);
      setOpenSnackbar(true);
      localStorage.removeItem('logoutMessage'); // Clear after displaying
    }
  }, []);

  useEffect(() => {
    let typingInterval;

    // Function to handle typing effect
    const handleTyping = () => {
      if (!reverse) {
        // Typing text1 and text2
        if (index < text1.length) {
          setDisplayText1(prev => prev + text1[index]);
        } else if (index < text1.length + text2.length) {
          setDisplayText2(prev => prev + text2[index - text1.length]);
        }
      }

      // If we have typed everything, start backspacing
      if (index === text1.length + text2.length && !reverse) {
        setReverse(true);
      }

      // Backspacing logic
      if (reverse) {
        if (displayText2.length > 0) {
          setDisplayText2(displayText2.slice(0, -1));
        } else if (displayText1.length > 0) {
          setDisplayText1(displayText1.slice(0, -1));
        } else {
          // Reset and start typing again with new color
          setReverse(false);
          setIndex(0);
          setDisplayText1('');
          setDisplayText2('');
          setTextColor(pastelColors[Math.floor(Math.random() * pastelColors.length)]); // Change color on reset
        }
      }

      setIndex(prevIndex => prevIndex + 1);
    };

    typingInterval = setInterval(handleTyping, reverse ? backspacingSpeed : typingSpeed);

    // Cleanup interval when done typing or backspacing
    return () => clearInterval(typingInterval);
  }, [index, displayText1, displayText2, reverse]);



  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  {/**const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post('https://admin.bizstor.lk/public/api/login', { email, password });
      const token = response.data.token;

      localStorage.setItem('token', token);
      setToken(token);

      handleSnackbar('Login successfully!', 'success');
      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      handleSnackbar('Login failed!', 'error');
      setLoading(false);
    }
  };*/}

  {/**const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
        const response = await axios.post('https://admin.bizstor.lk/public/api/login', { email, password });
        const token = response.data.token;

        if (token) {
            localStorage.setItem('token', token);
            setToken(token);
            handleSnackbar('Login successfully!', 'success');

            setTimeout(() => {
                navigate('/dashboard'); // Ensure token is stored before redirecting
            }, 200);
        } else {
            handleSnackbar('Invalid response from server!', 'error');
        }
    } catch (error) {
        handleSnackbar('Login failed!', 'error');
    } finally {
        setLoading(false);
    }
};*/}

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post("https://admin.bizstor.lk/public/api/login", { email, password });
      const token = response.data.token;

      if (token) {
        localStorage.setItem("token", token);
        setToken(token);
        handleSnackbar("Login successfully!", "success");
        setLoading(false);
        navigate("/dashboard");
      }
    } catch (error) {
      handleSnackbar("Login unsuccessfully!", "error");
      setLoading(false);
    }
  };


  const refreshToken = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await axios.get('https://admin.bizstor.lk/public/api/refresh-token', {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
    } catch (error) {
      console.error('Failed to refresh token:', error);
      handleSnackbar('Session expired. Please log in again.', 'error');
      localStorage.removeItem('token');
      navigate('/');
    }
  };

  useEffect(() => {
    const interval = setInterval(refreshToken, 23 * 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);


  React.useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, []);


  //googlelogin
  const handleGoogleSuccess = async (credentialResponse) => {
    setLoading(true);

    if (!credentialResponse?.credential) {
      handleSnackbar("Google Credential Missing", "error");
      setLoading(false);
      return;
    }

    try {
      // Send Google Token to backend for verification and registration
      const response = await axios.post("https://admin.bizstor.lk/public/api/google-register", {
        token: credentialResponse.credential,
      });

      const { user, authToken } = response.data;

      // Store authentication token in local storage
      localStorage.setItem("token", authToken);
      setToken(authToken);

      handleSnackbar("Login successfully!", "success");
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      handleSnackbar("Login failed!", "error");
      setLoading(false);
    }
  };



  return (
    <Container maxWidth="lg">
      <Helmet>
        <meta
          name="description"
          content="Bizstor.lk offers the most efficient POS system in Sri Lanka. Manage sales, inventory, and reports seamlessly with our user-friendly software."
        />
        <meta
          name="keywords"
          content="POS system Sri Lanka, Bizstor, inventory management, sales tracking, POS software Sri Lanka, online POS system"
        />
        <meta name="author" content="Bizstor.lk" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://bizstor.lk/" />
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundImage: { sm: `url(${BG01})`, xs: 'none' },
          backgroundSize: 'cover', // Ensures the image covers the entire background
          backgroundPosition: 'center', // Centers the image
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: { sm: 'flex', xs: 'none' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            //bgcolor: 'rgba(255, 255, 255, 0.8)',
            padding: 4,
          }}
        >

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start'
            }}
          >

            <motion.div
              initial={{ opacity: 0, height: 0, y: 20 }}
              animate={{ opacity: 1, height: 'auto', y: 0 }}
              transition={{ duration: 1.5 }}
              style={{ overflow: 'hidden' }}
            >
              <Typography variant="h2" component="h1"
                sx={{
                  fontFamily: 'monospace',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  color: 'white',
                  textAlign: 'left'
                }}
              >Bizstor
              </Typography>
            </motion.div>

            <Typography variant="subtitle1" sx={{
              mt: 2, fontWeight: 'bold', color: 'white',
            }}>
              SMART ACCOUNTING SOFTWARE FOR BUSINESSES.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold', color: 'text.secondary' }}>
              Manage inventory, track stock levels, manage expenses, generate invoices, analyze reports, and much more all in one platform.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>

          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // background: 'url(${BG}) center / cover no-repeat',
            //bgcolor: 'rgba(189, 189, 189, 0.8)',

          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 4,
              //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              bgcolor: 'white',
              //zIndex: 1,
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', sm: 'none' },
              }}
            >
              <h1>BIZSTOR</h1>
            </Box>

            <AnimatedTypography key={key} component="h1" variant="h6">
              Login
            </AnimatedTypography>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                <Link to="/forgot-password" style={{ textDecoration: 'none' }}>Forgot Password?</Link>
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: 20,
                  background: 'gray',
                  '&:hover': {
                    backgroundColor: 'black',
                  },
                }}
              >
                Login
              </Button>

              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ mb: 1, width: "100%" }}>
                <Stack direction="row" alignItems="center" spacing={1} width="100%" mb={1}>
                  <Divider sx={{ flex: 1 }} />
                  <Typography variant="body" sx={{ mx: 1, color: 'text.secondary' }}>
                    OR
                  </Typography>
                  <Divider sx={{ flex: 1 }} />
                </Stack>

                <GoogleLogin onSuccess={handleGoogleSuccess} onError={() => console.log("Login Failed")} />
              </Box>

              <Typography variant="body2" mt={2}>
                Don't have an account? <Link to="/register" style={{ textDecoration: 'none' }}>Create an Account</Link>
              </Typography>
              {loading && (
                <Box sx={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  zIndex: 9999
                }}>
                  <l-trio size="50" speed="1.3" color="red"></l-trio>
                </Box>
              )}
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={snackbarSeverity}
                  sx={{ width: '100%' }}
                >
                  {snackbarMessage}
                </Alert>
              </Snackbar>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={100000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ mt: 1 }} // Moves it up
              >
                <Alert onClose={() => setOpenSnackbar(false)} severity="warning">
                  {logoutMessage}
                </Alert>
              </Snackbar>

            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default Login;
