import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { monthOptions } from './MonthOption/monthOptions';
import { filterByDateRange } from './MonthOption/filterByDateRange';
import PrintIcon from '@mui/icons-material/Print';
import { printContent } from './Print/ProfitandLoss';
import { styled, keyframes } from '@mui/system';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from 'framer-motion';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const ProfitAndLoss = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [damages, setDamages] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchDate, setSearchDate] = useState('');
    const [selectedMonth, setSelectedMonth] = useState('');
    const [dateRange, setDateRange] = useState('');
    const [totalCostOfSales, setTotalCostOfSales] = useState(0);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const navigate = useNavigate();

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    {/**useEffect(() => {

        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);

            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);*/}

    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);



    const fetchData = async () => {
        setLoading(true);
        try {
            const ordersResponse = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Orders = ordersResponse.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setOrders(Orders);
                setLoading(false);
            }

            const damagesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Damages = damagesResponse.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setDamages(Damages);
                setLoading(false);
            }

            const expensesResponse = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Expenses = expensesResponse.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setExpenses(Expenses);
                setLoading(false);
            }

            const stocksResponse = await axios.get('https://admin.bizstor.lk/public/api/get-stock-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                const Stocks = stocksResponse.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setStocks(Stocks);
                setLoading(false);
            }


            const productResponse = await axios.get('https://admin.bizstor.lk/public/api/get-product-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}

                // Filter products based on usertoken matching console.log("");
                const Product = productResponse.data.products.filter(
                    (product) => product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setProducts(Product);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchDate(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    const filterByDate = (data, dateKey) => {
        if (!searchDate) return data;
        return data.filter(item => new Date(item[dateKey]).toDateString() === new Date(searchDate).toDateString());
    };

    const filterByMonth = (data, dateKey) => {
        if (!selectedMonth) return data;
        return data.filter(item => {
            const date = new Date(item[dateKey]);
            return date.getMonth() + 1 === parseInt(selectedMonth);
        });
    };

    const filteredOrders = filterByDate(filterByMonth(filterByDateRange(orders, 'invoicedate', dateRange), 'invoicedate'), 'invoicedate');
    const filteredDamages = filterByDate(filterByMonth(filterByDateRange(damages, 'invoicedate', dateRange), 'invoicedate'), 'invoicedate');
    const filteredExpenses = filterByDate(filterByMonth(filterByDateRange(expenses, 'date', dateRange), 'date'), 'date');
    const filteredStocks = filterByDate(filterByMonth(filterByDateRange(stocks, 'date', dateRange), 'date'), 'date');

    const calculateItemTotal = (item, order) => {
        const itemTax = (item.price * (item.tax / 100)) || 0;
        const itemTotalBeforeDiscount = item.price + itemTax;
        const discountPercentage = parseFloat(order.discount) || 0;
        const discountAmount = (itemTotalBeforeDiscount * discountPercentage) / 100;
        return itemTotalBeforeDiscount - discountAmount;
    };

    const groupedDamages = filteredDamages.reduce((acc, damage) => {
        damage.items.forEach(item => {
            const key = `${item.product}-${damage.type}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    rate: item.rate,
                    quantity: 0,
                    totalPrice: 0,
                    type: damage.type
                };
            }
            acc[key].quantity += item.quantity;
            acc[key].totalPrice += item.price;
        });
        return acc;
    }, {});


    const groupedExpenses = filteredExpenses.reduce((acc, expense) => {
    expense.items.forEach(item => {
        const { category, amount } = item;
        if (!acc[category]) {
            acc[category] = {
                category,
                totalAmount: 0,
            };
        }
        // Ensure amount is a number before adding to totalAmount
        acc[category].totalAmount += Number(amount);
    });
    return acc;
}, {});


    const groupedStocks = filteredStocks.reduce((acc, stock) => {
        stock.items.forEach(item => {
            const key = `${item.product}-${item.status}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    quantity: 0,
                    status: item.status
                };
            }
            acc[key].quantity += item.quantity;
        });
        return acc;
    }, {});

    const groupedOrders = filteredOrders.reduce((acc, order) => {
        order.items.forEach(item => {
            const key = `${item.product}-${item.rate}`;
            if (!acc[key]) {
                acc[key] = {
                    product: item.product,
                    rate: item.rate,
                    quantity: 0,
                    totalPrice: 0,
                    totalDiscount: 0,
                    totalTax: 0,
                    total: 0,
                };
            }
            acc[key].quantity += item.quantity;
            acc[key].totalPrice += item.rate * item.quantity;
            acc[key].totalDiscount += (item.rate * item.quantity * order.discount) / 100;
            acc[key].totalTax += (item.rate * item.quantity * item.tax) / 100;

            // Calculate base total
            acc[key].total = acc[key].totalPrice - acc[key].totalDiscount + acc[key].totalTax;
        });
        return acc;
    }, {});

    Object.values(groupedOrders).forEach(order => {
        const damageKey = `${order.product}-${order.rate}`;
        const damage = Object.values(groupedDamages).find(
            d => `${d.product}-${d.rate}` === damageKey && d.type === "money back"
        );

        // If damage exists and it's a "money back" type, adjust total
        const damageTotalPrice = damage ? damage.totalPrice : 0;
        order.total -= damageTotalPrice;
    });



    const { totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal } = Object.values(groupedOrders).reduce(
        (totals, { totalPrice, totalDiscount, totalTax, total }) => {
            totals.totalPriceAmount += totalPrice;
            totals.totalDiscountAmount += totalDiscount;
            totals.totalTaxAmount += totalTax;
            totals.grandTotal += total; // Use adjusted total here
            return totals;
        },
        {
            totalPriceAmount: 0,
            totalDiscountAmount: 0,
            totalTaxAmount: 0,
            grandTotal: 0,
        }
    );

    const { damageTotalQuantity, damageTotalPrice } = Object.values(groupedDamages).reduce(
        (totals, { quantity, totalPrice, type }) => {
            if (type === "money back") {
                totals.damageTotalPrice += Number(totalPrice);
            } else if (type === "replace") {
                totals.damageTotalQuantity += Number(quantity);
            }
            return totals;
        },
        {
            damageTotalQuantity: 0,
            damageTotalPrice: 0,
        }
    );

const expensesTotalAmount = Object.values(groupedExpenses).reduce(
    (total, { totalAmount }) => total + Number(totalAmount.toString().replace(/,/g, '')),
    0
).toFixed(2);


    const { stockTotalFilled, stockTotalEmpty } = Object.values(groupedStocks).reduce(
        (totals, { quantity, status }) => {
            if (status === "item") {
                totals.stockTotalFilled += quantity;
            } else if (status === "Empty") {
                totals.stockTotalEmpty += quantity;
            }
            return totals;
        },
        {
            stockTotalFilled: 0,
            stockTotalEmpty: 0,
        }
    );

    const handlePrint = () => {
        const selectedMonthName = selectedMonth ? monthNames[parseInt(selectedMonth) - 1] : 'All Months';

        printContent(
            groupedOrders, totalPriceAmount, totalDiscountAmount, totalTaxAmount, grandTotal,
            groupedDamages, damageTotalQuantity, damageTotalPrice,
            groupedExpenses, expensesTotalAmount,
            groupedStocks, stockTotalFilled, stockTotalEmpty,
            selectedMonthName,
        );
    };
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const profit = grandTotal - expensesTotalAmount - damageTotalPrice;

    const getBpriceForProduct = (productName) => {
        const matchingProduct = products.find((product) => product.product === productName);
        return matchingProduct ? matchingProduct.Bprice || 'N/A' : 'N/A';
    };

    const calculateTotal = (productName, quantity) => {
        const bprice = getBpriceForProduct(productName);
        return bprice * quantity;
    };

    useEffect(() => {
        let total = 0;
        Object.keys(groupedStocks).forEach((key) => {
            const stockItem = groupedStocks[key];
            if (stockItem.status === "item") {
                total += calculateTotal(stockItem.product, stockItem.quantity);
            }
        });
        setTotalCostOfSales(total);
    }, [groupedStocks, products]);



    const printReport = () => {
        // Array of month names from January to December
        const monthNames = [
            "All Month", "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        // Ensure selectedMonth is in the range of 1-12
        const selectedMonthName = monthNames[selectedMonth - 0];

        // Open a new print window
        const printWindow = window.open('', '_blank');
        const currentDate = new Date().toLocaleDateString();

        let printContent = `
            <html>
                <head>
                    <title>Profit & Loss</title>
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                            line-height: 1.6;
                            color: #333;
                        }
                        h2, h4, h5 {
                            text-align: center;
                        }
                          h5 {
                            border-bottom: 2px solid black;
                        }
                        .section {
                            display: flex;
                            justify-content: space-between;
                            margin: 20px 0;
                            margin-left: 15px;
                        }
                        .left, .right {
                            width: 48%;
                        }
                        .right-align {
                            text-align: right;
                        }
                        .bold {
                            font-weight: bold;
                        }
                        hr {
                        border: none;              
                        height: 1px;              
                        background-color: #333;    
                        margin: 20px 0;            
                    }
                    </style>
                </head>
                <body>
                    <h2>${user.companyname}</h2>
                    <h4>Profit & Loss</h4>
                    <h5>${selectedMonthName}</h5>
    
                    <h3>Income</h3>
                    <div class="section">
                        <div class="left bold">Product</div>
                        <div class="right right-align bold">TOTAL</div>
                    </div>
                    <div class="section">
                        <div class="left">
                            ${Object.values(groupedOrders).map(({ product }) => `
                                <p>${product}</p>
                            `).join('')}
                        </div>
                        <div class="right right-align">
                            ${Object.values(groupedOrders).map(({ total }) => `
                                <p>${Number(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                            `).join('')}
                        </div>
                    </div>

                    <div class="section" style="border-bottom:1px solid black;border-top:1px solid black;">
                        <div class="left bold">Total Income</div>
                        <div class="right right-align bold">Lkr. ${(Number(grandTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0)}</div>
                    </div>

    
                    <h3>Expenses</h3>
                    <div class="section">
                        <div class="left bold">Category</div>
                        <div class="right right-align bold"></div>
                    </div>
                    <div class="section">
                        <div class="left">
                            ${Object.values(groupedExpenses).map(({ category }) => `
                                <p>${category}</p>
                            `).join('')}
                        </div>
                        <div class="right right-align">
                            ${Object.values(groupedExpenses).map(({ totalAmount }) => `
                                <p>${Number(totalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>
                            `).join('')}
                        </div>
                    </div>
                    <hr />
                    <div class="section" style="border-bottom:1px solid black;border-top:1px solid black;">
                        <div class="left bold">Total Expenses</div>
                        <div class="right right-align bold">Lkr. ${expensesTotalAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                    </div>
    
                    <div class="section" style="border-bottom:1px solid black;border-top:1px solid black;">
                        <div class="left bold">Net Earnings</div>
                        <div class="right right-align bold">Lkr. ${(Number(grandTotal  || 0) - (expensesTotalAmount || 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                    </div>
                </body>
            </html>
        `;

        // Write content to the new window
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.focus();

        // Trigger the print dialog and handle the cancel action
        printWindow.print();

        // Optionally listen for the `afterprint` event to close the window (if desired)
        printWindow.onafterprint = () => {
            printWindow.close();
        };
    };



    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Profit and Loss
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Report - Profit and Loss
                    </Typography>
                </Box>
            </AnimatedTypography>


            <Box
           sx={{     display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                      marginTop: '10px',
                            },
      }}
            >
                <Typography variant="h4" >

                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections

                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>PROFIT & LOSS</h2>
                    </Box>   
                   
                <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
                        gap: 1, // Space between the TextFields
                        width: '80%', // Ensure it takes full width on smaller screens
                        alignItems: 'center', // Align items vertically
                        '@media (max-width: 600px)': {
                          margin: 'auto',
                        },
                    }}
                >
                    {/**  <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearchChange}
                        value={searchDate}
                        size="small"
                        type="date"
                        sx={{
                            width: '200px',
                            height: '40px'
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />*/}

                    <Select
                    variant="outlined"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                        displayEmpty
                        size="small"
                        margin="normal"
                        sx={{
                            width: '250px',
                            height: '40px',
                            mt: 1,
                            borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                        }}
                    >
                        {monthOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                    variant="outlined"
                        value={dateRange}
                        onChange={handleDateRangeChange}
                        displayEmpty
                        size="small"
                        margin="normal"
                        sx={{
                            width: '250px',
                            height: '40px',
                            mt: 1,
                            borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                        }}
                    >   <MenuItem value=""><em>All</em></MenuItem>
                        <MenuItem value="last7Days">Last 7 Days</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        <MenuItem value="last3Months">Last 3 Months</MenuItem>
                        <MenuItem value="lastYear">Last Year</MenuItem>
                    </Select>
                    </Box>
                    </motion.div>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                        }}
                    >
                        <IconButton onClick={toggleSearch}>
                    <SearchIcon />
                </IconButton>
                        <IconButton onClick={printReport}>
                            <PrintIcon />
                        </IconButton>

                    </Box>
                </Box>
                
                <Typography variant="h6" component="h1" gutterBottom
                    sx={{
                        ml: 3
                    }}
                >
                    Income
                </Typography>

                {/** 
                <Grid container sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                        <Typography variant="subtitle1"><b></b></Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'right' }}>
                        <Typography variant="subtitle1"><b>Total</b></Typography>
                    </Grid>
                </Grid> */}


                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle2" fontWeight="bold">Product</Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle2" fontWeight="bold">TOTAL</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(groupedOrders).map(({ product, total }, index) => (
                                <TableRow key={`grouped-order-${index}`}>
                                    <TableCell sx={{ textAlign: 'left', color: '#636363', fontSize: '14px' }}>{product}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', color: '#636363', fontSize: '14px' }}>{Number(total).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: '14px' }}>
                                    Total Income
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right', fontWeight: 'bold', fontSize: '14px' }}>
                                    Lkr.{Number(grandTotal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/**<Typography variant="h6" component="h1" gutterBottom
                    sx={{
                        mb: 2,
                        mt: 3,
                        ml: 3
                    }}>
                    Cost Of Sales
                </Typography>*/}



                <Typography variant="h6" component="h1" gutterBottom
                    sx={{
                        mb: 2,
                        mt: 3,
                        ml: 3
                    }}>
                    Expenses
                </Typography>
                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle2" fontWeight="bold">Category</Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle2" fontWeight="bold"></Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(groupedExpenses).map(({ category, totalAmount }, index) => (
                                <TableRow key={`grouped-expense-${index}`}>
                                    <TableCell sx={{ textAlign: 'left', color: '#636363', fontSize: '14px' }}>{category}</TableCell>
                                    <TableCell sx={{ textAlign: 'right', color: '#636363', fontSize: '14px' }}>
{Number(totalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left', fontWeight: 'bold', fontSize: '14px' }}>
                                    Total Expenses
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right', fontWeight: 'bold', fontSize: '14px' }}>
                                    Lkr.{expensesTotalAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle2" fontWeight="bold"></Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle2" fontWeight="bold"></Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }} colSpan={1}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}><b>Net Earnings</b></Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }} colSpan={1}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}><b>Lkr. {Number((grandTotal  || 0) - (expensesTotalAmount || 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b></Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {/** 
                <Typography variant="h6" component="h1" gutterBottom
                    sx={{
                        mb: 2,
                        mt: 3,
                        ml: 3
                    }}>
                    Cost of sales
                    {renderGroupedStocks()}
                </Typography>
                <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }}>
                                    <Typography variant="subtitle2" fontWeight="bold">Category</Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>
                                    <Typography variant="subtitle2" fontWeight="bold"></Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(groupedExpenses).map(({ category, totalAmount }, index) => (
                                <TableRow key={`grouped-expense-${index}`}>
                                    <TableCell sx={{ textAlign: 'left' }}>{category}</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>
                                        {totalAmount}
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell sx={{ textAlign: 'left' }} colSpan={1}>
                                    <Typography variant="subtitle1"><b>Total </b></Typography>
                                </TableCell>
                                <TableCell sx={{ textAlign: 'right' }} colSpan={1}>
                                    <Typography variant="subtitle1"><b>Lkr.{expensesTotalAmount}</b></Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
*/}



                {/**                <Box display="flex" justifyContent="flex-end" fontWeight="bold"
                    sx={{
                        mt: 3,

                    }}>
                    <p>Net Earnings: Lkr.{profit}</p>
                </Box>*/}
            </Box>
            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}
            <ScrollToTopButton />
        </Box>
    );
};

export default ProfitAndLoss;
