import React from 'react';
import { Box, Typography, Container, List, ListItem } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ my: 4 }}>
      <Box>
        <Typography variant="h4" gutterBottom textTransform={'uppercase'}>
          Privacy Policy 
        </Typography>

        <Typography variant="body1" paragraph>
          At BizStor, we are committed to protecting your privacy. This Privacy Policy outlines the types of personal
          information we collect, how we use it, and the steps we take to ensure your information is kept safe and secure.
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following types of information when you use BizStor:
        </Typography>
        <List>
          <ListItem>Personal Information: Name, Email (via Google OAuth registration)</ListItem>
          <ListItem>Usage Data: Information about how you interact with the BizStor system, such as log data, features used, etc.</ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We use your information to provide, maintain, and improve the BizStor POS system, including:
        </Typography>
        <List>
          <ListItem>Managing invoices and returns</ListItem>
          <ListItem>Tracking expenses</ListItem>
          <ListItem>Generating reports</ListItem>
          <ListItem>Communicating with you regarding updates or support</ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          3. Google OAuth Registration
        </Typography>
        <Typography variant="body1" paragraph>
          BizStor allows you to register and log in using Google OAuth. When you register, we will collect your Google account
          information, such as your name and email address, to create your account in the BizStor system. We do not store your
          Google password or any other sensitive credentials.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Data Security
        </Typography>
        <Typography variant="body1" paragraph>
          We take the security of your personal information seriously. We use industry-standard encryption and security practices
          to protect your data from unauthorized access or disclosure.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Sharing Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          We do not sell, rent, or share your personal information with third parties, except in the following cases:
        </Typography>
        <List>
          <ListItem>If required by law or legal process</ListItem>
          <ListItem>To service providers who help us operate BizStor (e.g., hosting providers)</ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          6. Your Choices
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
        </Typography>
        <List>
          <ListItem>Access, update, or delete your account information</ListItem>
          <ListItem>Opt-out of marketing communications from BizStor</ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          7. Changes to This Privacy Policy
        </Typography>
        <Typography variant="body1" paragraph>
          We may update this Privacy Policy from time to time. We will notify you of any significant changes by updating the
          date at the top of this policy.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns about this Privacy Policy, please contact us at bizstorlk@gmail.com.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
