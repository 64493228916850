import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import {
  LineChart, Line, BarChart, Bar, PieChart, Pie, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { useNavigate } from "react-router-dom";

const Chart = ({ onTotalAmountChange }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [fetchedData, setFetchedData] = useState([]);
  const [fetchedExpenses, setFetchedExpenses] = useState([]);
  const [fetchedDamageData, setFetchedDamageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dayByDayData, setDayByDayData] = useState([]);
  const [chartType, setChartType] = useState('line'); 
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  {/**useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return; // Exit if no token

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);*/}

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        navigate("/"); // Redirect if no token
        return;
      }

      try {
        const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/"); // Redirect on 401 error
        }
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
          const nisalOrders = response.data.filter(order =>
            order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );
          setFetchedData(nisalOrders);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchExpenses = async () => {
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
          const nisalExpenses = response.data.filter(expense =>
            expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );
          setFetchedExpenses(nisalExpenses);
        } catch (error) {
          console.error('Error fetching expenses data:', error);
        }
      };

      const fetchDamageData = async () => {
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
          const nisalDamages = response.data.filter(damage =>
            damage.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
            damage.type === 'money back'
          );
          setFetchedDamageData(nisalDamages);
        } catch (error) {
          console.error('Error fetching damage data:', error);
        }
      };

      fetchData();
      fetchExpenses();
      fetchDamageData();
    }
  }, [user.id]);

  useEffect(() => {
    if (!fetchedData.length && !fetchedExpenses.length && !fetchedDamageData.length) return;
  
    // Get current date and calculate the start of the week (Monday)
    const now = new Date();
    const currentDay = now.getDay(); // Sunday is 0, Monday is 1, etc.
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - (currentDay === 0 ? 6 : currentDay - 1)); // Set to Monday of this week
    startOfWeek.setHours(0, 0, 0, 0); // Ensure the start time is at midnight
  
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to Sunday of this week
    endOfWeek.setHours(23, 59, 59, 999); // Ensure the end time is at the last moment of Sunday
  
    // Initialize arrays to store totals for each day of the week
    const salesTotals = Array(7).fill(0);
    const expenseTotals = Array(7).fill(0);
    const damageTotals = Array(7).fill(0);
  
    // Helper function to determine the day index (0 for Monday, 6 for Sunday)
    const getDayIndex = (date) => {
      const dayOfWeek = date.getDay(); // 0 for Sunday, 6 for Saturday
      return dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Adjust so that Monday is 0, Sunday is 6
    };
  
    // Process fetched data for sales
    fetchedData.forEach(order => {
      const createdAt = new Date(order.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
        let orderTotal = 0;
  
        order.items.forEach(item => {
          const itemPrice = Number(item.price);
          const itemTax = item.tax || 0;
          const itemTotal = itemPrice + (itemPrice * (itemTax / 100));
          orderTotal += itemTotal;
        });
  
        const discountPercentage = order.discount || 0;
        const discountAmount = (orderTotal * discountPercentage) / 100;
        orderTotal -= discountAmount;
  
        salesTotals[dayIndex] += orderTotal;
      }
    });
  
    // Process fetched data for expenses
    fetchedExpenses.forEach(expense => {
      const createdAt = new Date(expense.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
  
        expense.items.forEach(item => {
          expenseTotals[dayIndex] += Number(item.amount);
        });
      }
    });
  
    // Process fetched data for damages
    fetchedDamageData.forEach(damage => {
      const createdAt = new Date(damage.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
  
        damage.items.forEach(item => {
          damageTotals[dayIndex] += Number(item.price);
        });
      }
    });
  
    // Map the totals to the days of the week
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  
    const data = daysOfWeek.map((day, index) => ({
      name: day,
      sellAmount: salesTotals[index],
      expenseAmount: expenseTotals[index],
      returnAmount: damageTotals[index],
    }));
  
    setDayByDayData(data);
  
    if (onTotalAmountChange) {
      onTotalAmountChange(data);
    }
  }, [fetchedData, fetchedExpenses, fetchedDamageData, onTotalAmountChange]);
    
  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const colors = [
    '#1E88E5', // Monday - Dark Blue
    '#43A047', // Tuesday - Dark Green
    '#F57C00', // Wednesday - Dark Orange
    '#D32F2F', // Thursday - Dark Red
    '#0288D1', // Friday - Dark Cyan
    '#8E24AA', // Saturday - Dark Magenta
    '#4A148C'  // Sunday - Dark Purple
  ];

  const totalReturnAmount = dayByDayData.reduce((sum, entry) => sum + entry.returnAmount, 0);
  const totalExpenseAmount = dayByDayData.reduce((sum, entry) => sum + entry.expenseAmount, 0);
  const totalSellAmount = dayByDayData.reduce((sum, entry) => sum + entry.sellAmount, 0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        maxWidth: 'auto',
        margin: 'auto',
        boxShadow: 1,
        padding: '10px',
        borderRadius: '5px',
        bgcolor: 'background.paper',
        marginTop: '30px',
        position: 'relative'
      }}
    >
      {loading ? (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          zIndex: 9999
        }}>
          <l-trio
            size="50"
            speed="1.3"
            color="red"
          ></l-trio>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Weekly Sales, Returns and Expenses
          </Typography>

          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-end',
              gap: '10px',
              mt: '5px',
              mb: '10px',
              top: '5px',
            }}
          >
            <FormControl 
                variant="outlined" sx={{ width: { xs: '100%', sm: '150px' } }}
            >
              <InputLabel id="chart-type-label">Select Chart Type</InputLabel>
              <Select
                labelId="chart-type-label"
                id="chart-type-select"
                value={chartType}
                onChange={handleChartTypeChange}
                label="Select Chart Type"
                size="small"
      sx={{ mr: { xs: 0, sm: 2 } }} 
              >
                <MenuItem value="line">Line Chart</MenuItem>
                <MenuItem value="bar">Bar Chart</MenuItem>
                <MenuItem value="pie">Pie Chart</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {chartType === 'line' && (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                data={dayByDayData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="sellAmount" stroke="#8884d8" strokeWidth={2} />
                <Line type="monotone" dataKey="expenseAmount" stroke="red" strokeWidth={2} />
                <Line type="monotone" dataKey="returnAmount" stroke="#ff7300" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          )}

          {chartType === 'bar' && (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={dayByDayData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="sellAmount" fill="#8884d8" />
                <Bar dataKey="expenseAmount" fill="red" />
                <Bar dataKey="returnAmount" fill="#ff7300" />
              </BarChart>
            </ResponsiveContainer>
          )}

          {chartType === 'pie' && (
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={[
                    { name: 'Return Amount', value: totalReturnAmount },
                    { name: 'Expense Amount', value: totalExpenseAmount },
                    { name: 'Sell Amount', value: totalSellAmount }
                  ]}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  label
                >
                  <Cell key="cell-return" fill="#FFB300" />
                  <Cell key="cell-expense" fill="red" />
                  <Cell key="cell-sell" fill="#8884d8" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Chart;
