import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsAuthenticated(!!token);
  }, []);

  if (isAuthenticated === null) return null; // Prevents flash effect

  return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;



{/**import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('token');

    // Redirect to "/" if there is no token
    return token ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;*/}



