import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Typography,
    Grid,
    Select,
    Snackbar,
    MenuItem,
    InputLabel,
    FormControl,
    ListSubheader,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, keyframes } from '@mui/system';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const ReturnInvoice = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [rows, setRows] = useState([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: new Date().toISOString().split('T')[0], tax: '' }]);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [payamount, setPayamount] = useState('');
    const [customer, setCustomer] = useState('');
    const [address, setBillingAddress] = useState('');
    const [term, setTerm] = useState('due on receipt');
    const [invoicedate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
    const [duedate, setDueDate] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCustomerData, setSelectedCustomerData] = useState({
        fullname: '',
        email: '',
        address: '',
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
    const navigate = useNavigate();

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    {/**useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);*/}


    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchProducts();
            fetchCustomers();
        }
    }, [user.id]);

    const fetchCustomers = () => {
        setLoading(true);
        axios.get('https://admin.bizstor.lk/public/api/get-customer-data')
            .then(response => {
                if (user.id) {
                    const Customers = response.data.customers.filter(customer =>
                        customer.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setCustomers(Customers);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customers:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        if (selectedValue === 'Direct Customer') {
            setSelectedCustomerData({
                id: '',
                fullname: '',
                email: '',
                address: '',
            });
        } else {
            const selectedCustomer = customers.find((customer) => customer.id === selectedValue);
            if (selectedCustomer) {
                setSelectedCustomerData({
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address,
                });
            }
        }
    };

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            product: '',
            quantity: '',
            rate: '',
            price: '',
            serviceDate: new Date().toISOString().split('T')[0],
            tax: ''
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };
                if (field === 'product') {
                    updatedRow.rate = productPrices[value] || '';
                    updatedRow.price = (updatedRow.rate || 0) * (row.quantity || 0);
                }
                if (field === 'quantity' || field === 'rate') {
                    updatedRow.price = (updatedRow.rate || 0) * (updatedRow.quantity || 0);
                }
                return updatedRow;
            }
            return row;
        });

        setRows(newRows);

        {/**  if (field === 'product' && value !== '' && index === rows.length - 1) {
            handleAddRow();
        }*/}
    };

    useEffect(() => {
        const invoiceDateObj = new Date(invoicedate);
        let daysToAdd = 0;

        switch (term) {
            case 'net 15':
                daysToAdd = 15;
                break;
            case 'net 30':
                daysToAdd = 30;
                break;
            case 'net 60':
                daysToAdd = 60;
                break;
            default:
                daysToAdd = 0;
        }

        invoiceDateObj.setDate(invoiceDateObj.getDate() + daysToAdd);
        setDueDate(invoiceDateObj.toISOString().split('T')[0]);
    }, [term, invoicedate]);

    const handleSubmit = async () => {
        setLoading(true);

        const hasInvalidCustomer = selectedOption === 'Direct Customer';

        if (hasInvalidCustomer) {
            handleSnackbar('Please fill the customer field.', 'error');
            setLoading(false);
            return;
        }

        const hasInvalidType = !type;

        if (hasInvalidType) {
            handleSnackbar('Please select the return type.', 'error');
            setLoading(false);
            return;
        }

        const hasInvalidRow = rows.some(row => !row.product || !row.quantity);

        if (hasInvalidRow) {
            handleSnackbar('Please fill in all required product and quantity fields.', 'error');
            setLoading(false);
            return;
        }

        try {
            const data = {
                usertoken: user.id,
                fullname: selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname,
                email: selectedOption === 'Direct Customer' ? email : selectedCustomerData.email,
                customer: selectedOption === 'Direct Customer' ? 'direct' : 'existing customer',
                address: selectedOption === 'Direct Customer' ? address : selectedCustomerData.address,
                invoicedate: invoicedate,
                type: type,
                description: description,
                items: rows.filter(row => row.product && row.quantity).map(row => ({
                    product: row.product,
                    quantity: row.quantity,
                    rate: row.rate,
                    price: row.price,
                    servicedate: row.servicedate || new Date().toISOString().split('T')[0],
                }))
            };

            {/**console.log('Data to be saved:', data);*/ } // Check if data is correctly structured

            const response = await axios.post('https://admin.bizstor.lk/public/api/save-damage-data', data);
            {/**console.log('return invoice saved successfully:', response.data);*/ }

            // Reset form fields and state after successful submission
            setFullname('');
            setEmail('');
            setType('');
            setPayamount('');
            setCustomer('');
            setBillingAddress('');
            setTerm('due on receipt');
            setInvoiceDate(new Date().toISOString().split('T')[0]);
            setDueDate(new Date().toISOString().split('T')[0]);
            setDescription('');
            setDiscount(0);
            setRows([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: '', tax: '' }]);

            handleSnackbar('return save successfully!', 'success');
            setLoading(false);

        } catch (error) {
            console.error('Error saving data:', error);
            // Handle error (e.g., display error message)
        } finally {
            setLoading(false);
        }
    };


    const calculateTotalAmount = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.price || 0), 0);
        return total;
    };

    const calculateTotalQuantity = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.quantity || 0), 0);
        return total;
    };



    const handleDirectCustomerChange = (field, value) => {
        if (field === 'fullname') {
            setFullname(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'address') {
            setBillingAddress(value);
        }
    };


    const handleCustomerTypeChange = (event) => {
        const type = event.target.value;
        setSelectedOption(type);
        setCustomer(type === 'Direct Customer' ? 'direct' : '');
    };
    const [products, setProducts] = useState([]);

    const fetchProducts = () => {
        setLoading(true);
        axios.get('https://admin.bizstor.lk/public/api/get-product-data')
            .then(response => {
                if (user.id) {
                    const Products = response.data.products.filter(product =>
                        product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setProducts(Products);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });
    };

    const productPrices = {};
    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Return
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Return
                    </Typography>
                </Box>
            </AnimatedTypography>
            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Return
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    marginBottom: '20px'
                }}
            >
                <Typography variant="h5"
                    sx={{
                        ml: 2
                    }}
                >

                </Typography>
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>RETURN</h2>
                    </Box> 
                <TableContainer>
                    <Table
                        sx={{
                            marginBottom: '30px',
                        }}
                    >
                        <TableHead>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <FormControl fullWidth variant="outlined" size="small">
                                                <InputLabel>Customer</InputLabel>
                                                <Select
                                                    value={selectedOption}
                                                    onChange={handleOptionChange}
                                                    fullWidth
                                                    label="Customer"
                                                    variant="outlined"
                                                    inputProps={{
                                                        style: { minWidth: '100%' }, // Adjust min-width for Select
                                                    }}
                                                >
                                                    <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                                                    <ListSubheader>Existing Customers</ListSubheader>
                                                    {customers.map((customer) => (
                                                        <MenuItem key={customer.id} value={customer.id}>
                                                            {customer.firstname} {customer.lastname}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="Full Name"
                                                variant="outlined"
                                                value={selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname}
                                                onChange={(e) => handleDirectCustomerChange('fullname', e.target.value)}
                                                disabled={selectedOption !== 'Direct Customer'}
                                                fullWidth
                                                size="small"
                                                InputProps={{
                                                    style: { width: '100%' },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="Email"
                                                variant="outlined"
                                                value={selectedOption === 'Direct Customer' ? email : selectedCustomerData.email}
                                                onChange={(e) => handleDirectCustomerChange('email', e.target.value)}
                                                disabled={selectedOption !== 'Direct Customer'}
                                                fullWidth
                                                size="small"
                                                InputProps={{
                                                    style: { width: '100%' },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                label="Billing Address"
                                                variant="outlined"
                                                value={selectedOption === 'Direct Customer' ? address : selectedCustomerData.address}
                                                onChange={(e) => handleDirectCustomerChange('address', e.target.value)}
                                                disabled={selectedOption !== 'Direct Customer'}
                                                fullWidth
                                                size="small"
                                                style={{ width: '100%' }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box>
                                                <FormControl variant="outlined" fullWidth size="small">
                                                    <InputLabel id="type-label">Return Type</InputLabel>
                                                    <Select
                                                        labelId="type-label"
                                                        id="type"
                                                        value={type}
                                                        onChange={(e) => setType(e.target.value)}
                                                        label="Return Type"
                                                        style={{ width: '100%' }}
                                                    >
                                                        <MenuItem value="money back"><em>Money Back</em></MenuItem>
                                                        <MenuItem value="replace">Replace</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                type="date"
                                                label="Invoice Date"
                                                value={invoicedate}
                                                onChange={(e) => setInvoiceDate(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                style={{ width: '100%' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>


                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'right',
                            justifyContent: 'flex-end',
                            marginTop: '10px',
                            textAlign: 'right',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {type === 'money back' ? 'TOTAL' : 'QTY'}
                        </Typography>
                        <Typography variant="h4" fontWeight="bold">
                            {type === 'money back' ? `Lkr.${calculateTotalAmount().toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : calculateTotalQuantity()}
                        </Typography>
                    </Box>

                </TableContainer>
                <TableContainer style={{ marginTop: '15px' }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Rate</TableCell>
                                <TableCell>Amount</TableCell>
                                {/**   <TableCell>Service Date</TableCell>*/}
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                value={row.product}
                                                onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                                fullWidth
                                                size="small"
                                                sx={{ width: '150px',
                                                    '@media (max-width: 600px)': {
                                                width: '150px',
                                            },
                                                 }} // Adjusted height and width
                                            >
                                                {Object.keys(productPrices).map((product) => (
                                                    <MenuItem key={product} value={product}>
                                                        {product}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.quantity}
                                            onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                            fullWidth
                                            size="small"
                                            sx={{ width: '150px' }} // Adjusted width and height
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.rate}
                                            onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                            fullWidth
                                            size="small"
                                            sx={{ width: '100px' }} // Adjusted width
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={Number(row.price).toFixed(2)}
                                            onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                            disabled
                                            fullWidth
                                            size="small"
                                            sx={{ width: '150px' }} // Adjusted width and height
                                        />
                                    </TableCell>
                                    {/**   <TableCell>
                                    <TextField
                                        type="date"
                                        value={row.servicedate || new Date().toISOString().split('T')[0]}
                                        onChange={(e) => handleInputChange(index, 'servicedate', e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size="small"
                                        sx={{ width: '150px' }}
                                    />
                                </TableCell>*/}

                                    <TableCell>
                                        <IconButton
                                            onClick={() => handleDeleteRow(index)}
                                            size="small"
                                            sx={{
                                                '&:hover': {
                                                    color: 'red',
                                                },
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>


                <Box display="flex" justifyContent="flex-start" marginTop="15px">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddRow}
                        size="small"
                        style={{
                            backgroundColor: 'black',
                            color: 'white'
                        }}
                    >
                        <PlaylistAddIcon />
                    </Button>
                </Box>

                <Grid container spacing={1} style={{ marginTop: '15px' }}>
                    <Grid item xs={6}>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            variant="outlined"
                            fullWidth
                            multiline  // Enable multiline input
                            rows={4}  // Adjust the number of rows as needed
                            size="small"
                        />
                    </Grid>
                </Grid>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}


                <Box display="flex" justifyContent="flex-end" marginTop="15px">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        size="small"
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#356bc4',
                            },
                            marginBottom: { xs: '8px', sm: '0' }
                        }}
                        sx={{
                            '@media (max-width: 600px)': {
                              mr:0,
                            },
                        }}
                        >

                        Submit
                    </Button>

                </Box>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default ReturnInvoice;
