import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Box,
    Typography,
    Snackbar,
    Alert,
} from '@mui/material';
import ProductTable from '../Addform/Tables/ProductTable';
import { styled, keyframes } from '@mui/system';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));


const AddProduct = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const navigate = useNavigate();

    const [product, setProductName] = useState('');
    const [price, setProductPrice] = useState('');
    const [Bprice, setProductBPrice] = useState('');
    const [code, setProductCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [image, setImage] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity


    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    {/**useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);*/}

    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    const handleSubmit = async () => {
        setLoading(true);

        const hasInvalidProduct = !product;

        if (hasInvalidProduct) {
            handleSnackbar('Please enter the product name.', 'error');
            setLoading(false);
            return;
        }

        const hasInvalidPrice = !price;

        if (hasInvalidPrice) {
            handleSnackbar('Please enter the selling price.', 'error');
            setLoading(false);
            return;
        }

        const data = new FormData();
        data.append('usertoken', user.id);
        data.append('product', product);
        data.append('Bprice', Bprice);
        data.append('price', price);
        data.append('code', code);
        if (image) data.append('image', image);

        try {
            const response = await axios.post('https://admin.bizstor.lk/public/api/save-product-data', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setProductName('');
            setProductPrice('');
            setProductBPrice('');
            setProductCode('');
            setImage(null);

            handleSnackbar('Product added successfully!', 'success');
            setLoading(false);

        } catch (error) {
            console.error('Error saving data:', error.response || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    return (
        <Box>
             <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Add Product
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Add - Product
                    </Typography>
                </Box>
            </AnimatedTypography>
            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Add / Product
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: 1,
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h5">
                    
                </Typography>
                {/**<hr style={{ borderTop: '1px solid #6d7081', width: '100%', margin: '10px 0' }} />*/}
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>ADD PRODUCT</h2>
                    </Box>
                <Box
                    sx={{
                        m: 2
                    }}
                >
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginBottom: '20px', marginTop: '20px' }}>
                    <TextField
                        label="Product Name"
                        value={product}
                        onChange={(e) => setProductName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginBottom: { xs: '10px', sm: '0' }, marginRight: { sm: '10px' } }} // Adjust margin for responsiveness
                    />

                    <TextField
                        label="Buying Price"
                        type="number"
                        value={Bprice}
                        onChange={(e) => setProductBPrice(e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginBottom: { xs: '10px', sm: '0' }, marginLeft: { sm: '10px' } }} // Adjust margin for responsiveness
                    />

                    <TextField
                        label="Selling Price"
                        type="number"
                        value={price}
                        onChange={(e) => setProductPrice(e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginBottom: { xs: '10px', sm: '0' }, marginLeft: { sm: '10px' } }} // Adjust margin for responsiveness
                    />

                    <TextField
                        label="Code"
                        type="number"
                        value={code}
                        onChange={(e) => setProductCode(e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        sx={{ marginBottom: { xs: '10px', sm: '0' }, marginLeft: { sm: '10px' } }} // Adjust margin for responsiveness
                    />
                </Box>

                <Box sx={{ marginBottom: '20px' }}>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        style={{ marginTop: '20px' }}
                    />
                </Box>
                </Box>


                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            marginTop: '30px',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#356bc4',
                            },
                            marginBottom: { xs: '8px', sm: '0' }
                        }}                    >
                        Add Product
                    </Button>
                </div>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <ProductTable />
        </Box>
    );
};

export default AddProduct;
