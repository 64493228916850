import React from 'react';
import { useLocation } from 'react-router-dom';
import Appbar from './appbar';

const AppWrapper = ({ children }) => {
    const location = useLocation();
    const hideAppbarPaths = ['/', '/register', '/forgot-password','/privacy-policy'];

    const isPasswordResetRoute = location.pathname.startsWith('/password-reset/');

    const showAppbar = !hideAppbarPaths.includes(location.pathname) && !isPasswordResetRoute;

    return (
        <div className="App">
            {showAppbar && <Appbar />}
            {children}
        </div>
    );
};

export default AppWrapper;
