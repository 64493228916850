import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import {
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password.length <= 6) {
      handleSnackbar("Password must be greater than 6 characters!", "error");
      setLoading(false);
      return;
    }

    if (password !== cpassword) {
      handleSnackbar("Passwords do not match!", "error");
      setLoading(false);
      return;
    }

    if (!captchaToken) {
      handleSnackbar("Please complete the reCAPTCHA!", "error");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post("https://admin.bizstor.lk/public/api/register", {
        name,
        email,
        password,
        status: "active",
        "g-recaptcha-response": captchaToken, // Sending reCAPTCHA token to the backend
      });

      if (response.status === 201 || response.data.success) {
        handleSnackbar("Registered successfully!", "success");
        setLoading(false);
        navigate("/");
      } else {
        throw new Error("Registration failed");
      }
    } catch (error) {
      handleSnackbar("Registration failed!", "error");
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "95vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          bgcolor: "white",
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <h1>BIZSTOR</h1>
        <Typography component="h1" variant="h5">
        Create an Account
        </Typography>
        <Box component="form" onSubmit={handleRegister} sx={{ mt: 1 }}>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            label="Confirm Password"
            type="password"
            value={cpassword}
            onChange={(e) => setCPassword(e.target.value)}
          />

          {/* Google reCAPTCHA */}
          <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}>
            <ReCAPTCHA
              sitekey="6LcAfMwqAAAAAHhL5kE4nje6w0s_xBJR6j-w7spl" 
              onChange={handleCaptchaChange}
            />
          </Box>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              borderRadius: 20,
              background: "gray",
              "&:hover": { backgroundColor: "black" },
            }}
          >
            Create Account
          </Button>

          <Typography variant="body2" sx={{ mt: 2 }}>
            Already have an account?{" "}
            <Link to="/" style={{ textDecoration: "none" }}>Login here</Link>
          </Typography>
        </Box>
      </Box>

      {loading && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "white",
            zIndex: 9999,
          }}
        >
          <l-trio size="50" speed="1.3" color="red"></l-trio>
        </Box>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Register;
