import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Checkbox,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions, Grid,
  FormControl,
  InputLabel,
  Menu,
  Toolbar,
  AppBar,
  Slide,
  TablePagination,
  Snackbar,
  Alert
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, keyframes } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ScrollToTopButton from '../../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InvestmentTable = () => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);

  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [currentEditData, setCurrentEditData] = useState(null);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(0); // For pagination
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  const [alertOpen, setAlertOpen] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSearch = () => {
      setIsSearchOpen(!isSearchOpen);
  };
  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchInvestments();
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  {/**useEffect(() => {

    const fetchProfile = async () => {
      if (!token) return; // Exit if no token

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);

      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);*/}

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        navigate("/"); // Redirect if no token
        return;
      }

      try {
        const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUser(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/"); // Redirect on 401 error
        }
      }
    };

    fetchProfile();
  }, []);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return;

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);

  useEffect(() => {
    if (user.id) {
      fetchInvestments();
    }
  }, [user.id]);

  const fetchInvestments = async () => {
    try {
      const response = await axios.get('https://admin.bizstor.lk/public/api/get-investment');

      if (user.id) {

        const filteredInvestments = response.data.investment.filter(investment =>
          investment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
        );

        setInvestments(filteredInvestments);
      } else {
        setInvestments([]); // In case user.id is not found, return an empty array
      }
    } catch (error) {
      console.error('Error fetching investment data:', error);
    } finally {
      setLoading(false);
    }
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredInvestments = investments.filter((investment) => {
    const investmentDate = new Date(investment.date);
    const investmentMonth = investmentDate.toLocaleString('default', { month: 'long' }).toLowerCase();
    const isMonthMatch = selectedMonth === '' || investmentMonth === selectedMonth.toLowerCase();

    const isSearchMatch =
      investment.person?.toLowerCase().includes(searchTerm) ||
      investment.to?.toLowerCase().includes(searchTerm) ||
      investment.from?.toLowerCase().includes(searchTerm) ||
      investment.amount?.toString().includes(searchTerm) ||
      investment.date?.includes(searchTerm);

    return isMonthMatch && isSearchMatch;
  });

  const handleRowSelect = (event, investmentId) => {
    const selectedIndex = selectedRows.indexOf(investmentId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, investmentId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelected);
  };

  const handleDelete = async () => {
    setLoading(true);
    setOpenEditDialog(false); // Close the edit dialog
    setDeleteConfirmationOpen(false); // Close the delete confirmation dialog
    try {
      const promises = selectedRows.map(async (id) => {
        const response = await axios.delete(`https://admin.bizstor.lk/public/api/delete-investment/${id}`);
        return response.status === 200;

      });
      const results = await Promise.all(promises);

      if (results.every((result) => result)) {
        setInvestments(investments.filter(investment => !selectedRows.includes(investment.id)));
        setSelectedRows([]);
        handleSnackbar('investment deleted successfully!', 'success');
      } else {
        handleSnackbar('error deleting investment!', 'error');

      }
    } catch (error) {
      handleSnackbar('error deleting investment!', 'error');
    } finally {
      setLoading(false);
    }
  };
  // Handle opening and closing of the dialog
  const handleEditClick = (investment) => {
    setCurrentEditData(investment);
    setFrom(investment.from); // Set the from value from the investment
    setTo(investment.to); // Set the to value from the investment
    setOpenEditDialog(true);
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false);
    setCurrentEditData(null);
  };

  // Handle form submission for editing investment
  const handleDialogSubmit = async () => {
    try {
      await axios.put(`https://admin.bizstor.lk/public/api/update-investment/${currentEditData.id}`, currentEditData);
      fetchInvestments(); // Refresh the data
      handleDialogClose();
    } catch (error) {
      console.error('Error updating investment:', error);
    }
  };

  const handleEditChange = (field, value) => {
    setCurrentEditData({ ...currentEditData, [field]: value });
  };

  const handleOpenDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Slice data for current page
  const paginatedInvestments = filteredInvestments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const fetchSuppliers = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://admin.bizstor.lk/public/api/suppliers');
      console.log(response.data); // Log the response to check the structure
      if (user.id) {
        const filteredSuppliers = response.data.filter(supplier =>
          supplier.usertoken.toLowerCase() === user.id.toString().toLowerCase()
        );
        setSuppliers(filteredSuppliers);
      }
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchSuppliers();
  }, [user]);

  return (
    <Box>
      <AnimatedTypography
        component="h1"
        sx={{
          ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
          mt: { xs: 2, sm: 3 },
          color: 'secondary.main',
          letterSpacing: '0.01em',
          fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component="span"
            sx={{
              display: { xs: 'none', sm: 'inline' }, 
              fontSize: { xs: '14px', sm: '20px' },
fontWeight: 'bold',
              color: '',
            }}
          >
            Investment
          </Typography>
          {/* Hide separator, icon, and text on small screens */}
          <Typography
            component="span"
            sx={{
              display: { xs: 'none', sm: 'inline' }, // Hidden on xs
              ml: 1,
              fontSize: '14px',
              color: '',
            }}
          >
            |
          </Typography>
          <HomeIcon
            sx={{
              ml: 1,
              fontSize: '18px',
              color: '#6d7081',
            }}
          />
          <Typography
            component="span"
            sx={{
              ml: 0.5,
              fontSize: '14px',
              color: '#6d7081',
            }}
          >
            - Report - Investment
          </Typography>
        </Box>
      </AnimatedTypography>

      {/**<AnimatedTypography
        component="h1"
        sx={{
          ml: 3,
          mt: 3,
          fontSize: '1rem',
          color: 'secondary.main',
          letterSpacing: '0.01em'
        }}
      >
        Dashboard / Report / Investment
      </AnimatedTypography>*/}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '95%',
          maxWidth: 'auto',
          margin: 'auto',
          //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          padding: '10px',
          borderRadius: '5px',
          bgcolor: 'white',
          marginTop: '30px',
          position: 'relative',
        }}
      >
        <Typography variant="h4" >

        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            gap: 2,
          }}
        >
           <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>INVESTMENT</h2>
                    </Box>   
                   
                <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            >
          <Box
            sx={{
              display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically
'@media (max-width: 600px)': {
                              margin: 'auto',
                            },
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              onChange={handleSearchChange}
              value={searchTerm}
              size="small"
              placeholder="Search…"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '250px',
                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
               }}
            />

            <Select
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              size="small"
              sx={{ width: '250px', height: '40px', mt: 1,
                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
               }}
            >
              <MenuItem value="">
                <em>All Months</em>
              </MenuItem>
              {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
                <MenuItem key={month} value={month}>{month}</MenuItem>
              ))}
            </Select>
          </Box>
          </motion.div>

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              mt: { xs: 2, sm: 0 },
              justifyContent: { xs: 'center', sm: 'flex-end' },
              width: { xs: '100%', sm: 'auto' },
            }}
          >
<IconButton onClick={toggleSearch}>
                    <SearchIcon />
                </IconButton>

            <IconButton
              aria-label="delete"
              onClick={handleMenuOpen}
              disabled={selectedRows.length === 0}
            >
              <DeleteIcon
                sx={{
                  '&:hover': {
                    color: 'red',
                  },
                }}
              />
            </IconButton>

            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleOpenDeleteConfirmation}>
                Delete Selected Investment
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleCloseDeleteConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected investments?"}</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDeleteConfirmation} color="primary">
              No
            </Button>
            <Button onClick={handleDelete} 
            sx={{
              backgroundColor:'red',
              color:'white',
              '&:hover': {
                  color: 'white',
                  backgroundColor:'red',
                  },
          }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <TableContainer  sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedRows.length > 0 && selectedRows.length < investments.length}
                    checked={selectedRows.length === investments.length}
                    onChange={() => {
                      if (selectedRows.length === investments.length) {
                        setSelectedRows([]);
                      } else {
                        setSelectedRows(investments.map((investment) => investment.id));
                      }
                    }}
                  />
                </TableCell>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Person</strong></TableCell>
                <TableCell><strong>From</strong></TableCell>
                <TableCell><strong>To</strong></TableCell>
                <TableCell><strong>Amount</strong></TableCell>
                <TableCell><strong>Date</strong></TableCell>
                <TableCell><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999,
                      }}
                    >
                      <l-trio size="50" speed="1.3" color="red"></l-trio>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                paginatedInvestments.length > 0 && paginatedInvestments
                  .sort((a, b) => new Date(b.id) - new Date(a.id))
                  .map((investment) => (
                    <TableRow key={investment.id} selected={selectedRows.includes(investment.id)}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRows.includes(investment.id)}
                          onChange={(event) => handleRowSelect(event, investment.id)}
                        />
                      </TableCell>
                      <TableCell sx={{ fontSize: '14px' }}>{investment.id}</TableCell>
                      <TableCell sx={{ fontSize: '14px' }}>{investment.person || 'N/A'}</TableCell>
                      <TableCell sx={{ fontSize: '14px' }}>{investment.from}</TableCell>
                      <TableCell sx={{ fontSize: '14px' }}>{investment.to}</TableCell>
                      <TableCell sx={{ fontSize: '14px' }}>{Number(investment.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                      <TableCell sx={{ fontSize: '14px' }}>{new Date(investment.date).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditClick(investment)}>
                          <EditNoteIcon
                            sx={{
                              '&:hover': {
                                color: 'black',
                              },
                            }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>


        <Box
                                display="flex"
                                justifyContent="center"
                                width="100%"
                                mt={2}
                            >
        <TablePagination
          component="div"
          count={filteredInvestments.length} // Total number of investments
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /></Box>

        <Dialog
          open={openEditDialog}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
          fullWidth
          maxWidth="md"
        >
          <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
                <CloseIcon sx={{ color: 'white' }} />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                Edit Investment
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}> {/* Set consistent spacing */}

              {/* Transfer From */}
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="from-label">Transfer From</InputLabel>
                  <Select
                    labelId="from-label"
                    id="transfer-from"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    label="Transfer From"
                  >
                    {to !== 'bank' && <MenuItem value="bank">Bank</MenuItem>}
                    {to !== 'credit' && <MenuItem value="credit">Credit</MenuItem>}
                    {to !== 'capital' && <MenuItem value="capital">Capital</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>

              {/* Transfer To */}
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="to-label">Transfer To</InputLabel>
                  <Select
                    labelId="to-label"
                    id="transfer-to"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    label="Transfer To"
                  >
                    {from !== 'bank' && <MenuItem value="bank">Bank</MenuItem>}
                    {from !== 'credit' && <MenuItem value="credit">Credit</MenuItem>}
                    {from !== 'capital' && <MenuItem value="capital">Capital</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>

              {/* Person Field */}
              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <InputLabel id="person-label">Person</InputLabel>
                  <Select
                    labelId="person-label"
                    id="person-select"
                    value={currentEditData?.person || ''}
                    onChange={(e) => handleEditChange('person', e.target.value)}
                    label="Person"
                  >
                    {suppliers.map((supplier) => (
                      <MenuItem key={supplier.id} value={supplier.supplier_name}>
                        {supplier.supplier_name}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>


              {/* Amount Field */}
              <Grid item xs={12}>
                <TextField
                  label="Amount"
                  fullWidth
                  size="small"
                  type="number"
                  value={currentEditData?.amount || ''}
                  onChange={(e) => handleEditChange('amount', e.target.value)}
                />
              </Grid>

              {/* Date Field */}
              <Grid item xs={12}>
                <TextField
                  label="Date"
                  fullWidth
                  size="small"
                  type="date"
                  value={currentEditData?.date ? currentEditData.date.split('T')[0] : ''}
                  onChange={(e) => handleEditChange('date', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            {/**  <Button onClick={handleDialogClose}>Cancel</Button>*/}
            <Button onClick={handleDialogSubmit} >Save</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{
              width: '100%',

            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
      <ScrollToTopButton />
    </Box>
  );
};

export default InvestmentTable;
