import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar, ListSubheader,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Select,
    FormControl,
    InputLabel,
    Slide,
    AppBar,
    Toolbar,
    Grid,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { quantum, trio } from 'ldrs';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { printReturnRows } from './Print/ReturnPrint';
import { styled, keyframes } from '@mui/system';
import ScrollToTopButton from '../../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

quantum.register();
trio.register();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ReturnTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [selecteddamage, setSelecteddamage] = useState(null);
    const [payments, setPayments] = useState([]);
    const [open, setOpen] = useState(false);
    const [editReturn, seteditReturn] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [products, setProducts] = useState([]);
    const productPrices = {};
    const [customers, setCustomers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const selectedRowsData = fetchedData.filter((damage) => selectedRows.includes(damage.id));
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const navigate = useNavigate();

    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };



    {/**useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);*/}

    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);

    const handleClickOpen = () => setOpen(true);


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            if (user.id) {

                // Filter orders based on user.id
                const nisalOrders = response.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );

                setFetchedData(nisalOrders);
                setFilteredData(nisalOrders);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, damageId) => {
        const selectedIndex = selectedRows.indexOf(damageId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, damageId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (damageId) => selectedRows.indexOf(damageId) !== -1;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleDelete = async () => {
        setLoading(true);
        try {
            // Delete selected rows
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/public/api/delete-damage-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(damage => !selectedRows.includes(damage.id)));
                handleSnackbar('Selected return deleted successfully.!', 'success');
                setAlertOpen(true); // Open Snackbar on successful delete
                setSelectedRows([]); // Clear selected rows
                setDeleteConfirmationOpen(false); // Close delete confirmation dialog
                fetchData();
            } else {
                handleSnackbar('Failed deleting return damage!', 'error');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            handleSnackbar('Error deleting return damage!', 'error');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    {/*updated*/ }

    const handleEditClick = (damage) => {
        seteditReturn(damage);
        setEditDialogOpen(true);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        seteditReturn(null);
    };

    const handleEditChange = (field, value) => {
        const updateddamage = { ...editReturn, [field]: value };



        seteditReturn(updateddamage);
    };


    const handleEditItemChange = (index, field, value) => {
        const updatedItems = [...editReturn.items];
        updatedItems[index][field] = value;
        seteditReturn(prevState => ({ ...prevState, items: updatedItems }));
    };

    const handleAddNewItem = () => {
        // Get the current date in the desired format (e.g., YYYY-MM-DD)
        const currentDate = new Date().toISOString().split('T')[0];

        seteditReturn(prevState => ({
            ...prevState,
            items: [
                ...prevState.items,
                {
                    product: '',
                    quantity: '',
                    rate: '',
                    price: '',
                    servicedate: currentDate, // Set servicedate to current date
                    tax: ''
                }
            ]
        }));
    };


    const handleEditSave = async () => {
        setLoading(true); // Start loading

        

        try {
            const response = await axios.put(`https://admin.bizstor.lk/public/api/update-damage-order/${editReturn.id}`, {
                fullname: editReturn.fullname,
                customer: editReturn.customer === 'Direct Customer' ? 'Direct Customer' : 'Existing Customer',
                address: editReturn.address,
                email: editReturn.email,
                type: editReturn.type,
                invoicedate: editReturn.invoicedate,
                description: editReturn.description,

                items: editReturn.items.map(item => ({
                    product: item.product,
                    quantity: item.quantity,
                    rate: item.rate,
                    price: item.price,
                    servicedate: item.servicedate,
                }))
            });
            console.log(response.data);
            setEditDialogOpen(false);
            handleSnackbar('Return update successfully!', 'success');
            fetchData();
        } catch (error) {
            console.error('There was an error updating the damage:', error.response.data);
            handleSnackbar('Failded to updated return!', 'error');

        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        axios.get('https://admin.bizstor.lk/public/api/get-product-data')
            .then(response => {
                if (user.id) {
                    {/**console.log('User ID:', user.id);*/}
    
                    // Filter products based on user token
                    const filteredProducts = response.data.products.filter(product =>
                        product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setProducts(filteredProducts);
                } else {
                    setProducts(response.data.products); // Set all products if no user token
                }
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, [user.id]); // Dependency array includes user.id
    

    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    const handleProductChange = (index, value) => {
        const rate = productPrices[value];
        handleEditItemChange(index, 'product', value);
        handleEditItemChange(index, 'rate', rate);
        const quantity = editReturn.items[index].quantity || 0;
        handleEditItemChange(index, 'price', rate * quantity);
    };

    const handleQuantityChange = (index, value) => {
        handleEditItemChange(index, 'quantity', value);
        const rate = editReturn.items[index].rate || 0;
        handleEditItemChange(index, 'price', rate * value);
    };

    const handleRateChange = (index, value) => {
        handleEditItemChange(index, 'rate', value);
        const quantity = editReturn.items[index].quantity || 0;
        handleEditItemChange(index, 'price', value * quantity);
    };

    const handleDeleteItem = (index) => {
        const updatedItems = [...editReturn.items];
        updatedItems.splice(index, 1);
        seteditReturn({ ...editReturn, items: updatedItems });
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                setLoading(true); // Start loading
                const response = await axios.get('https://admin.bizstor.lk/public/api/get-customer-data');
                
                if (user.id) {
                    {/**console.log('User ID:', user.id);*/}
                    
                    // Filter customers based on user token
                    const filteredCustomers = response.data.customers.filter(customer =>
                        customer.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setCustomers(filteredCustomers);
                } else {
                    setCustomers(response.data.customers); // Set all customers if no user token
                }
            } catch (error) {
                console.error('Error fetching customers:', error);
            } finally {
                setLoading(false); // End loading
            }
        };
    
        fetchCustomers();
    }, [user.id]); // Dependency array includes user.id
    


    const handleAutoFill = (field, value) => {
        if (field === 'customer') {
            if (value === 'Direct Customer') {
                seteditReturn(prevState => ({
                    ...prevState,
                    customer: value,
                    fullname: '',
                    email: '',
                    address: ''
                }));
            } else {
                const selectedCustomer = customers.find(customer => customer.id === value);
                seteditReturn(prevState => ({
                    ...prevState,
                    customer: value,
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address
                }));
            }
        } else {
            seteditReturn(prevState => ({
                ...prevState,
                [field]: value
            }));
        }
    };

    const calculateTotalPrice = () => {
        // Check if editReturn and items are available
        if (!editReturn || !editReturn.items) return 0;

        // Calculate total with item-specific taxes included
        const total = editReturn.items.reduce((total, item) => {
            // Ensure item.price and item.tax are valid numbers
            const itemPrice = parseFloat(item.price) || 0;
            const itemTax = parseFloat(item.tax) || 0;

            const itemTotal = itemPrice + (itemPrice * (itemTax / 100)); // Adding item-specific tax
            return total + itemTotal;
        }, 0);

        // Get discount percentage and pay amount, ensuring they are valid numbers
        const discountPercentage = parseFloat(editReturn.discount) || 0;
        const payAmount = parseFloat(editReturn.payamount) || 0;

        // Calculate discount amount and final total
        const discountAmount = (total * discountPercentage) / 100;
        const finalTotal = total - discountAmount - payAmount;

        return finalTotal.toFixed(2);
    };

    const calculateAllQuantity = () => {
        // Check if editReturn and items are available
        if (!editReturn || !editReturn.items) return 0;

        // Calculate total quantity
        const totalQuantity = editReturn.items.reduce((total, item) => {
            // Ensure item.quantity is a valid number
            const itemQuantity = parseFloat(item.quantity) || 0;
            return total + itemQuantity;
        }, 0);

        return totalQuantity;
    };

    const [selectedMonth, setSelectedMonth] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value, selectedMonth);
    };

    const handleMonthChange = (event) => {
        const value = event.target.value;
        setSelectedMonth(value);
        filterData(searchTerm, value);
    };

    const filterData = (term, month) => {
        const filtered = fetchedData.filter(damage => {
            const damageDate = new Date(damage.invoicedate);
            const damageMonth = damageDate.toLocaleString('default', { month: 'long' }).toLowerCase();
            return (
                (term === '' ||
                    damage.id.toString().includes(term) ||
                    damage.fullname.toLowerCase().includes(term) ||
                    damage.invoicedate.includes(term)) &&
                (month === '' || damageMonth === month.toLowerCase())
            );
        });
        setFilteredData(filtered);
    };
    return (
        <Box>
             <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Return
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Report - Return
                    </Typography>
                </Box>
            </AnimatedTypography>

            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Return
            </AnimatedTypography>*/}
            <Box
           sx={{     display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                      marginTop: '10px',
                            },
      }}
            >
                <Typography variant="h4">
                    
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' }, // Responsive direction
                        justifyContent: 'space-between', // Space between left and right groups
                        alignItems: 'center', // Align items vertically
                        p: 2, // Padding inside the box
                        gap: 2, // Space between sections

                    }}
                >
                     <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>RETURN</h2>
                    </Box>   
                   
                <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            >
                    {/* Left side: Search fields */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 1, // Space between the TextFields
                            width: '80%', // Ensure it takes full width on smaller screens
                            alignItems: 'center', // Align items vertically
'@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                        }}
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            placeholder="Search…"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px',
                                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                             }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            type="date"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px',
                                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                             }}
                        />
                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            size="small"
                            sx={{
                                width: '250px',
                                height: '40px',
                                mt: 1,
                                borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                            }}
                        >
                            <MenuItem value="">
                                <em>All Months</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                        </Select>

                    </Box></motion.div>

                    {/* Right side: Icons */}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1, // Space between the icons
                        }}
                    >
                        <IconButton onClick={toggleSearch}>
                    <SearchIcon />
                </IconButton>
                        <IconButton
                            aria-label="print"
                            onClick={() => printReturnRows(selectedRowsData)}
                            disabled={selectedRows.length === 0}
                        >
                            <PrintIcon />
                        </IconButton>
                        <IconButton
                            aria-label="delete"
                            onClick={handleMenuOpen}
                            disabled={selectedRows.length === 0}
                        >
                            <DeleteIcon
                                sx={{
                                    '&:hover': {
                                        color: 'red',
                                    },
                                }}
                            />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleOpenDeleteConfirmation}>
                                Delete Selected Return
                            </MenuItem>
                        </Menu>
                    </Box>
                </Box>


                <Dialog
                    open={deleteConfirmationOpen}
                    onClose={handleCloseDeleteConfirmation}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected return damage?"}</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteConfirmation} color="primary">
                            No
                        </Button>
                        <Button onClick={handleDelete} 
                        sx={{
                            backgroundColor:'red',
                            color:'white',
                            '&:hover': {
                                color: 'white',
                                backgroundColor:'red',
                                },
                        }}
                        >
                            Yes
                        </Button>
                    </DialogActions>

                    {loading && (
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'white',
                            zIndex: 9999
                        }}>
                            <l-trio
                                size="50"
                                speed="1.3"
                                color="red"
                            ></l-trio>
                        </Box>
                    )}
                </Dialog>

                <TableContainer  style={{ marginTop: '20px',  }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                        checked={selectedRows.length === fetchedData.length}
                                        onChange={() => {
                                            if (selectedRows.length === fetchedData.length) {
                                                setSelectedRows([]);
                                            } else {
                                                setSelectedRows(fetchedData.map((damage) => damage.id));
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell><b>ID</b></TableCell>
                                <TableCell><b>Full Name</b></TableCell>
                                <TableCell><b>Customer Type</b></TableCell>
                                <TableCell><b>Return Date</b></TableCell>
                                <TableCell><b>Return Type</b></TableCell>
                                <TableCell><b>Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <Box sx={{
                                    position: 'fixed',
                                    top: 0,
                                    left: 0,
                                    width: '100vw',
                                    height: '100vh',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'white', // Optional: to give a slightly white overlay
                                    zIndex: 9999
                                }}>
                                    <l-trio
                                        size="50"
                                        speed="1.3"
                                        color="red"
                                    ></l-trio>
                                </Box>
                            ) : (
                                // Render fetched data
                                filteredData.sort((a, b) => new Date(b.id) - new Date(a.id))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((damage, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                onClick={() => handleRowClick(index)}
                                                role="checkbox"
                                                aria-checked={isSelected(damage.id)}
                                                tabIndex={-1}
                                                selected={isSelected(damage.id)}
                                                style={{ backgroundColor: openRow === index ? '#f0f0f0' : 'inherit' }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isSelected(damage.id)}
                                                        onChange={(event) => handleRowSelect(event, damage.id)}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {damage.id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {damage.fullname}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {damage.customer}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {damage.invoicedate}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        {damage.type}
                                                    </Typography>
                                                </TableCell>


                                                <TableCell>
                                                    <Box alignItems="center">
                                                        <IconButton onClick={() => handleEditClick(damage)}>
                                                            <EditNoteIcon
                                                                sx={{
                                                                    '&:hover': {
                                                                        color: 'black',
                                                                    },
                                                                }} />
                                                        </IconButton>

                                                    </Box>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                        <Box margin={1} padding={2} bgcolor="#f9f9f9" bdamageRadius={4} >
                                                            <Typography variant="h6" gutterBottom component="div" color="primary">
                                                                damage Details
                                                            </Typography>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell><b>Product</b></TableCell>
                                                                        <TableCell><b>Qty</b></TableCell>
                                                                        <TableCell><b>Rate</b></TableCell>
                                                                        <TableCell><b>Price</b></TableCell>
                                                                        <TableCell><b>Service Date</b></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {damage.items.map((item, idx) => (
                                                                        <TableRow key={idx}>
                                                                            <TableCell sx={{color: '#636363', fontSize: '14px' }}>{item.product}</TableCell>
                                                                            <TableCell sx={{color: '#636363', fontSize: '14px' }}>{item.quantity}</TableCell>
                                                                            <TableCell sx={{color: '#636363', fontSize: '14px' }}>Rs.{item.rate}</TableCell>
                                                                            <TableCell sx={{color: '#636363', fontSize: '14px' }}>Rs.{item.price}</TableCell>
                                                                            <TableCell sx={{color: '#636363', fontSize: '14px' }}>{item.servicedate}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} align="right">
                                                                            <b>
                                                                                {damage.type === 'money back' ? 'Total Money Back' : 'Total Quantity'}
                                                                            </b>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {damage.type === 'money back' ? (
                                                                                // Calculate the grand total for money back
                                                                                <>Rs.{damage.items.reduce((acc, item) => acc + (Number(item.quantity) * item.rate), 0)}</>
                                                                            ) : (
                                                                                // Calculate the total quantity
                                                                                damage.items.reduce((acc, item) => acc + Number(item.quantity), 0)
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>

                                                                </TableBody>

                                                            </Table>
                                                            <Typography sx={{ fontSize: '14px' }} gutterBottom component="div" color="textSecondary" mt={2}>
                                                                Description
                                                            </Typography>
                                                            <Typography variant="body2" gutterBottom component="div">
                                                                {damage.description}
                                                            </Typography>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>


                                            </TableRow>
                                        </React.Fragment>
                                    ))
                            )}
                        </TableBody>
                    </Table>
<Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={2}
                    ><TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={fetchedData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    /></Box>
                    
                </TableContainer>



                {/*popup dialog */}
                <Box>
                    <Dialog
                        open={editDialogOpen}
                        onClose={() =>
                            setEditDialogOpen(false)}
                        TransitionComponent={Transition}
                        maxWidth="xl"
                    >
                        <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit" onClick={handleCloseEditDialog} aria-label="close">
                                    <CloseIcon sx={{ color: 'white' }} />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                    Edit Return damage
                                </Typography>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth variant="outlined" size="small">
                                        <InputLabel>Customer</InputLabel>
                                        <Select
                                            label="Customer"
                                            value={editReturn?.customer || ''}
                                            onChange={(e) => handleAutoFill('customer', e.target.value)}
                                            fullWidth
                                            size="small"
                                        >
                                            <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                                            <ListSubheader>Existing Customers</ListSubheader>
                                            {customers.map((customer) => (
                                                <MenuItem key={customer.id} value={customer.id}>
                                                    {customer.firstname}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        label="Full Name"
                                        value={editReturn?.fullname || ''}
                                        onChange={(e) => handleAutoFill('fullname', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        label="Email"
                                        value={editReturn?.email || ''}
                                        onChange={(e) => handleAutoFill('email', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        label="Address"
                                        value={editReturn?.address || ''}
                                        onChange={(e) => handleAutoFill('address', e.target.value)}
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Type</InputLabel>
                                        <Select
                                            value={editReturn?.type || ''}
                                            onChange={(e) => handleEditChange('type', e.target.value)}
                                            label="Type"
                                        >
                                            <MenuItem value="money back">Money Back</MenuItem>
                                            <MenuItem value="replace">Replace</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                        label="Invoice Date"
                                        value={editReturn?.invoicedate || ''}
                                        onChange={(e) => handleEditChange('invoicedate', e.target.value)}
                                        fullWidth
                                        type="date"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>


                            <Box
                                sx={{
                                    textAlign: 'right',
                                    marginTop: '10px',
                                    marginRight: '10px',
                                    padding: '10px',
                                }}
                            >
                                {editReturn?.type === 'money back' ? (
                                    <>
                                        <h3 style={{ margin: 0 }}>Money Back Amount</h3>
                                        <p style={{ fontSize: '24px', margin: 0 }}>Lkr.-{calculateTotalPrice()}</p>
                                    </>
                                ) : (
                                    <>
                                        <h3 style={{ margin: 0 }}>Qty</h3>
                                        <p style={{ fontSize: '24px', margin: 0 }}>{calculateAllQuantity()}</p>
                                    </>
                                )}
                            </Box>


                            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px', marginBottom: '10px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddNewItem}
                                    style={{ backgroundColor: 'black', color: 'white' }}
                                    sx={{
                                        height: '30px',
                                    }}
                                >
                                    <PlaylistAddIcon />
                                </Button>
                            </Box>



                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product</TableCell>
                                            <TableCell>Qty</TableCell>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Service Date</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {editReturn?.items.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel>Product</InputLabel>
                                                        <Select
                                                            value={item.product}
                                                            onChange={(e) => handleProductChange(index, e.target.value)}
                                                            label="Product"
                                                        >
                                                            {Object.keys(productPrices).map((product) => (
                                                                <MenuItem key={product} value={product}>
                                                                    {product}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Quantity"
                                                        value={item.quantity}
                                                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Rate"
                                                        value={item.rate}
                                                        onChange={(e) => handleRateChange(index, e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        label="Price"
                                                        value={item.price}
                                                        onChange={(e) => handleEditItemChange(index, 'price', e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        type="date"
                                                        value={item.servicedate}
                                                        onChange={(e) => handleEditItemChange(index, 'servicedate', e.target.value)}
                                                        fullWidth
                                                        size="small"
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <IconButton onClick={() => handleDeleteItem(index)}>
                                                        <DeleteIcon
                                                            sx={{
                                                                '&:hover': {
                                                                    color: 'red',
                                                                },
                                                            }}
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        margin="normal"
                                        label="Description"
                                        multiline
                                        rows={3}
                                        size="small"
                                        value={editReturn?.description || ''}
                                        onChange={(e) => handleAutoFill('description', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setEditDialogOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleEditSave} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Alert
                            onClose={handleSnackbarClose}
                            severity={snackbarSeverity}
                            sx={{
                                width: '100%',

                            }}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
            <ScrollToTopButton/>
        </Box>
    );
};

export default ReturnTable;
