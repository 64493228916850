import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Snackbar,
    Alert,
    TablePagination,
    Slide,
    AppBar,
    Toolbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; 
import EditIcon from '@mui/icons-material/Edit'; 
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import ScrollToTopButton from '../../Button/ScrollToTopButton';
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ExpensesTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const navigate = useNavigate();
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedExpensesId, setSelectedExpensesId] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedExpense, setSelectedExpense] = useState({ id: '', expenses: '' });
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  
    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    
      const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
    

    {/**useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);*/}

    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchExpenses();
        }
    }, [user.id]);



    const fetchExpenses = () => {
        setLoading(true); // Set loading to true before fetching data

        axios.get('https://admin.bizstor.lk/public/api/get-expenses-category-data')
            .then(response => {
                if (user.id) {
                    const filterExpenses = response.data.expenses.filter(expense => 
                        expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setExpenses(filterExpenses);
                    setFilteredData(filterExpenses); 
    
                }
                
                setLoading(false); // Set loading to false after data is fetched
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false); // Set loading to false on error
            });
    };


    useEffect(() => {
        fetchExpenses();
    }, []); // Fetch products on component mount


    const handleDelete = (expenseId) => {
        setSelectedExpensesId(expenseId); // Set the selected product ID for deletion
        setOpenConfirmation(true); // Open confirmation dialog
    };

    const confirmDelete = () => {
        setLoading(true); // Set loading to true before delete operation

        // Perform delete operation
        axios.delete(`https://admin.bizstor.lk/public/api/delete-expenses-category-data/${selectedExpensesId}`)
            .then(response => {
                // Filter out the deleted product from state
                setExpenses(expenses.filter(expense => expense.id !== selectedExpensesId));
                setLoading(false); // Set loading to false after deletion

                // Show success alert
                setOpenConfirmation(false); // Close confirmation dialog
                handleSnackbar('Expenses deleted successfully!', 'success');
                setOpenAlert(true); // Open alert
                fetchExpenses();
            })
            .catch(error => {
                console.error('Error deleting product:', error);
                setLoading(false); // Set loading to false on error

                // Show error alert
                setOpenConfirmation(false); // Close confirmation dialog
                handleSnackbar('Failed to deleted expenses!', 'error');
                setOpenAlert(true); // Open alert
            });
    };

    // Handle edit action
    const handleEdit = (expense) => {
        setSelectedExpense(expense);
        setOpenEditDialog(true);
    };

    // Save the edited expense
    const saveEdit = () => {
        setLoading(true); // Set loading to true before update operation

        // Perform update operation
        axios.put(`https://admin.bizstor.lk/public/api/update-expenses-category-data/${selectedExpense.id}`, selectedExpense)
            .then(response => {
                // Update the expense in state
                setExpenses(expenses.map(expense => expense.id === selectedExpense.id ? selectedExpense : expense));
                setLoading(false); // Set loading to false after update

                // Show success alert
                setOpenEditDialog(false); // Close edit dialog
                handleSnackbar('Expenses updated successfully!', 'success');
                setOpenAlert(true); // Open alert
                fetchExpenses();
            })
            .catch(error => {
                console.error('Error updating expense:', error);
                setLoading(false); // Set loading to false on error

                // Show error alert
                setOpenEditDialog(false); // Close edit dialog
                handleSnackbar('Failed to updated expenses!', 'error');
                setOpenAlert(true); // Open alert
            });
    };

    // Handle input change for the edit form
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedExpense({ ...selectedExpense, [name]: value });
    };

    // Alert state
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Pagination event handlers
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value);
    };
    
    const filterData = (term) => {
        const filtered = expenses.filter(expense =>
            expense.id.toString().includes(term) ||
            expense.expenses.toLowerCase().includes(term) 
        );
        setFilteredData(filtered);
    };
    

    return (
        <Box
            sx={{
                display: 'flex',
          flexDirection: 'column',
          width: '95%',
          maxWidth: 'auto',
          margin: 'auto',
          //boxShadow: 1,
          padding: '10px',
          borderRadius: '5px',
          bgcolor: 'white',
          marginTop: '30px',
          position: 'relative'
            }}
        >
            <Typography variant="h5">
                
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Space between left and right groups
                    alignItems: 'center', // Align items vertically
                    p: 2, // Padding inside the box
                }}
            >
                {/* Left side: Search fields */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2, // Space between the TextFields
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: '250px',
                            borderRadius: '20px',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '20px',
                            },
                         }}
                    />
                
                </Box>
            </Box>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                   <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                </Box>
            )}

            {!loading && (
                <>
                    <TableContainer  sx={{ marginTop: '20px' }} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/**<TableCell sx={{ fontWeight: 'bold' }}></TableCell>*/}
                                    <TableCell sx={{ fontWeight: 'bold' }}>expenses</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(expense => (
                                        <TableRow key={expense.id}>
                                            {/**<TableCell></TableCell>*/}
                                            <TableCell>{expense.expenses}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEdit(expense)} aria-label="edit"
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'blue',
                                                        },
                                                    }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(expense.id)} aria-label="delete"
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'red',
                                                        },
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={2}
                    >
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={expenses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Box>
                </>
            )}

            {/* Delete confirmation dialog */}
            <Dialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                aria-labelledby="delete-dialog-title"
                fullWidth
            >
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this expenses?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary" autoFocus>
                        No
                    </Button>
                    <Button onClick={confirmDelete} 
                    sx={{
                        backgroundColor:'red',
                        color:'white',
                        '&:hover': {
                            color: 'white',
                            backgroundColor:'red',
                            },
                    }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit dialog */}
            <Dialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                TransitionComponent={Transition}
                aria-labelledby="edit-dialog-title"
                fullWidth
            >
                <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit"  onClick={() => setOpenEditDialog(false)}  aria-label="close">
                                    <CloseIcon sx={{ color: 'white' }} />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                Edit Expense
                                </Typography>
                            </Toolbar>
                        </AppBar>
                <DialogContent>
                    {/** 
                    <TextField
                        label="ID"
                        fullWidth
                        margin="normal"
                        value={selectedExpense.id}
                        disabled
                        InputProps={{
                            readOnly: true,
                        }}
                    />*/}
                    <TextField
                        label="Expenses category"
                        fullWidth
                        size='small'
                        margin="normal"
                        name="expenses"
                        value={selectedExpense.expenses}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}  
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{
              width: '100%',
              
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
<ScrollToTopButton/>
        </Box>
    );
};

export default ExpensesTable;
