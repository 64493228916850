import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const Bankamount = ({ onTotalAmountChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [fetchedExpensesData, setFetchedExpensesData] = useState([]);
    const [fetchedData, setFetchedData] = useState([]);
    const [damages, setDamages] = useState([]);
    const [fetchedPaymentData, setFetchedPaymentData] = useState([]);
    const [fetchedAllPaymentData, setFetchedAllPaymentData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    {/**useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, [token]);*/}

    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchData();
            fetchDamages();
            fetchPaymentData();
            fetchPayments();
            fetchExpensesData();
        }
    }, [user.id]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                const orders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
                    order.term === 'due on receipt'
                );
                setFetchedData(orders);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchPaymentData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                const orders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
                    order.term !== 'due on receipt'
                );
                setFetchedPaymentData(orders);
            }
        } catch (error) {
            console.error('Error fetching payment data:', error);
        }
    };

    const fetchPayments = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-payment-data');
            if (user.id) {
                const payments = response.data.filter(payment =>
                    payment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedAllPaymentData(payments);
            }
        } catch (error) {
            console.error('Error fetching payments:', error);
        }
    };

    const fetchDamages = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            if (user.id) {
                const damages = response.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setDamages(damages);
            }
        } catch (error) {
            console.error('Error fetching damages:', error);
        }
    };

    const fetchExpensesData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}
                const Expenses = response.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedExpensesData(Expenses);
            }
        } catch (error) {
            console.error('Error fetching expenses data:', error);
        }
    };

    useEffect(() => {

        let total = 0;
        fetchedData.forEach(order => {
            let orderTotal = 0;
            order.items.forEach(item => {
                const itemPrice = Number(item.price);
                const itemTax = item.tax || 0;
                const itemTotal = itemPrice + (itemPrice * (itemTax / 100)); // Adding item-specific tax
                orderTotal += itemTotal;
            });

            const discountPercentage = order.discount || 0;
            const discountAmount = (orderTotal * discountPercentage) / 100; // Calculate discount amount
            orderTotal -= discountAmount; // Apply discount

            total += orderTotal;
        });

        let totalPay = 0;
        fetchedPaymentData.forEach(order => {
            let orderTotal = 0;
            orderTotal += Number(order.payamount) || 0; // Add payamount directly
            totalPay += orderTotal;
        });

        let totalExpenses = 0;
        fetchedExpensesData.forEach(expense => {
            let expensesTotal = 0;
            expense.items.forEach(item => {
                expensesTotal += Number(item.amount);
            });

            totalExpenses += expensesTotal;
               
        });

        let totalAllPayment = 0;
        fetchedAllPaymentData.forEach(payment => {
            let paymentTotal = 0;
            paymentTotal += Number(payment.amount) || 0; // Add payment amount directly
            totalAllPayment += paymentTotal;
        });

        const { damageTotalPrice } = damages.reduce(
            (totals, damage) => {
                damage.items.forEach(item => {
                    if (damage.type === 'money back') {
                        totals.damageTotalPrice += Number(item.price);
                    }
                });
                return totals;
            },
            { damageTotalPrice: 0 }
        );

        const finalTotal = (total - damageTotalPrice + totalPay + totalAllPayment - totalExpenses).toFixed(2);

        if (onTotalAmountChange) {
            onTotalAmountChange(parseFloat(finalTotal)); // Send finalTotal to parent component
        }
    }, [fetchedData, damages, onTotalAmountChange, fetchedPaymentData, fetchedAllPaymentData,fetchedExpensesData]);

    return null; // No UI for this component
};

export default Bankamount;
