import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Box,
    Typography,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Snackbar,
    Alert,
    TextField,
    TablePagination,
    Grid,
    Slide,
    Avatar,
    AppBar,
    Toolbar,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import ScrollToTopButton from '../../Button/ScrollToTopButton';
import { useNavigate } from "react-router-dom";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const navigate = useNavigate();

    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Confirmation dialog state
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);

    // Edit dialog state
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editingCustomer, setEditingCustomer] = useState({});

    // Alert state
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [image, setImage] = useState(null);

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    
      const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
    

    {/**useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);*/}


    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchCustomers();
        }
    }, [user.id]);


    const fetchCustomers = () => {
        setLoading(true);

        axios.get('https://admin.bizstor.lk/public/api/get-customer-data')
            .then(response => {
                if (user.id) {
                    const filterCustomer = response.data.customers.filter(customer => 
                        customer.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setCustomers(filterCustomer);
                    setFilteredData(filterCustomer); 
    
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customer data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCustomers();
    }, []); // Fetch customers on component mount


    const handleDelete = (customerId) => {
        setSelectedCustomerId(customerId);
        setOpenConfirmation(true);
    };

    const confirmDelete = () => {
        setLoading(true);

        axios.delete(`https://admin.bizstor.lk/public/api/delete-customer-data/${selectedCustomerId}`)
            .then(response => {
                setCustomers(customers.filter(customer => customer.id !== selectedCustomerId));
                setLoading(false);
                setOpenConfirmation(false);
                handleSnackbar('Customer deleted successfully!', 'success');
                setOpenAlert(true);
            })
            .catch(error => {
                console.error('Error deleting customer:', error);
                setLoading(false);
                setOpenConfirmation(false);
                handleSnackbar('Failed to deleted Customer!', 'error');
                setOpenAlert(true);
            });
    };

    const handleEditClick = (customer) => {
        setEditingCustomer(customer);
        setOpenEditDialog(true);
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditingCustomer({
            ...editingCustomer,
            [name]: value
        });
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleEditSubmit = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append('firstname', editingCustomer.firstname);
        formData.append('lastname', editingCustomer.lastname);
        formData.append('email', editingCustomer.email);
        formData.append('phone', editingCustomer.phone);
        formData.append('address', editingCustomer.address);
        formData.append('nic', editingCustomer.nic);

        if (image) {
            formData.append('image', image);
        }

        axios.post(`https://admin.bizstor.lk/public/api/update-customer-data/${editingCustomer.id}`,  formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }) 
        .then(response => {
                setCustomers(customers.map(customer =>
                    customer.id === editingCustomer.id ? response.data.customer  : customer
                ));
                setLoading(false);
                setOpenEditDialog(false);
                handleSnackbar('Customer details updated successfully!', 'success');
                setOpenAlert(true);
                 fetchCustomers();
            })
            .catch(error => {
                console.error('Error updating customer:', error);
                setLoading(false);
                handleSnackbar('Failed to update customer!', 'error');
                setOpenAlert(true);
            });
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value);
    };
    
    // Function to filter product data based on search term
    const filterData = (term) => {
        const filtered = customers.filter(customers =>
            customers.id.toString().includes(term) ||
            customers.phone.toLowerCase().includes(term) ||
            customers.firstname.toLowerCase().includes(term) ||
            customers.lastname.toLowerCase().includes(term) ||
            customers.email.toLowerCase().includes(term) ||
            customers.nic.toLowerCase().includes(term) 
        );
        setFilteredData(filtered);
    };

    return (
        <Box
            sx={{
                display: 'flex',
          flexDirection: 'column',
          width: '95%',
          maxWidth: 'auto',
          margin: 'auto',
          //boxShadow: 1,
          padding: '10px',
          borderRadius: '5px',
          bgcolor: 'white',
          marginTop: '30px',
          position: 'relative'
            }}
        >
            <Typography variant="h5">
                
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Space between left and right groups
                    alignItems: 'center', // Align items vertically
                    p: 2, // Padding inside the box
                }}
            >
                {/* Left side: Search fields */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2, // Space between the TextFields
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ 
                            width: '250px', 
                            borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px',
                                    },
                        }}
                    />
                
                </Box>
            </Box>

            {loading && (
                        <Box sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100vw',
                            height: '100vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'white', // Optional: to give a slightly white overlay
                            zIndex: 9999
                        }}>
                            <l-trio
                                size="50"
                                speed="1.3"
                                color="red"
                            ></l-trio>
                        </Box>
                    )}

            {!loading && (
                <>
                    <TableContainer  sx={{ marginTop: '20px' }} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {/**<TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>*/}
                                    <TableCell sx={{ fontWeight: 'bold' }}>image</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Full Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Address</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>NIC</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map(customer => (
                                        <TableRow key={customer.id}>
                                            {/**<TableCell>{customer.id}</TableCell>*/}
                                            <TableCell>
                                    {customer.image ? (
                                        <Avatar
                                            src={`https://admin.bizstor.lk/storage/app/public/${customer.image}`}
                                            alt={customer.firstname}
                                            sx={{ width: 60, height: 60, borderRadius: '50%' }}
                                        />
                                    ) : (
                                        <Avatar
                                            sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: 'grey' }}
                                        />
                                    )}
                                </TableCell>
                                            <TableCell>{customer.firstname} {customer.lastname} </TableCell>
                                            <TableCell>{customer.email}</TableCell>
                                            <TableCell>{customer.address}</TableCell>
                                            <TableCell>{customer.phone}</TableCell>
                                            <TableCell>{customer.nic}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleEditClick(customer)} aria-label="edit"
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'blue',
                                                        },
                                                    }}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(customer.id)} aria-label="delete"
                                                    sx={{
                                                        '&:hover': {
                                                            color: 'red',
                                                        },
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={2}
                    >
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={customers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    </Box>
                </>
            )}

            {/* Delete confirmation dialog */}
            <Dialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                aria-labelledby="delete-dialog-title"
                fullWidth
            >
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this customer?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary" autoFocus>
                        No
                    </Button>
                    <Button onClick={confirmDelete} 
                    sx={{
                        backgroundColor:'red',
                        color:'white',
                        '&:hover': {
                            color: 'white',
                            backgroundColor:'red',
                            },
                    }}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit customer dialog */}
            <Dialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                TransitionComponent={Transition}
                aria-labelledby="edit-dialog-title"
                fullWidth
                maxWidth="md" 
            >
                
                <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpenEditDialog(false)} aria-label="close">
                            <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Edit Customer
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="First Name"
                                name="firstname"
                                value={editingCustomer.firstname}
                                onChange={handleEditChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Last Name"
                                name="lastname"
                                value={editingCustomer.lastname}
                                onChange={handleEditChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Email"
                                name="email"
                                value={editingCustomer.email}
                                onChange={handleEditChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Address"
                                name="address"
                                value={editingCustomer.address}
                                onChange={handleEditChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Phone"
                                name="phone"
                                value={editingCustomer.phone}
                                onChange={handleEditChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="NIC"
                                name="nic"
                                value={editingCustomer.nic}
                                onChange={handleEditChange}
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Grid>
                        <Grid
                        sx={{
                            ml:2
                        }}
                        >
                        <input
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        style={{ marginTop: '20px' }}
                    />
                    </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}  
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{
              width: '100%',
              
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <ScrollToTopButton/>
        </Box>
    );
};

export default CustomerTable;
