import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Paper,Button } from '@mui/material';
import {  AttachMoney, AccountBalance, MoneyOff,KeyboardArrowUp   } from '@mui/icons-material';
import Monthlyincome from '../Incomeandexpenses/Monthlyincome';
import Anualincome from '../Incomeandexpenses/Anualincome';
import Expenses from '../Incomeandexpenses/Expenses';
import Damages from '../Incomeandexpenses/Returns';
import Chart3 from '../Chart/Chart3';
import Stock from '../Home/Stock';
import Invoice from '../Popupdialogbox/Invoice';
import axios from 'axios';
import { styled, keyframes } from '@mui/system';
import Bank from '../Incomeandexpenses/BankBalance';
import Profit from '../Incomeandexpenses/Profit';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import PeopleIcons from '@mui/icons-material/PersonAdd'; 
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import Return from '../Popupdialogbox/Return';
import Expenses2 from '../Popupdialogbox/Expenses';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';
import Barchart from '../Admin/Barchart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link as RouterLink } from 'react-router-dom';
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Card = ({ title, value, icon: Icon, color, month, orders, showViewButton = true }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                p: 2,
                bgcolor: 'background.paper',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                borderLeft: '3px solid orange',
                ml: 4,
                mr: 4,
                mt: 2,
                height: '100px',
            }}
        >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" color={color} fontWeight="bold" textTransform="uppercase" mb={1}>
                    {title}
                </Typography>
                <Icon fontSize="large" style={{ color: 'gray', marginLeft: 8 }} />
            </Box>
            <Box>
                <Typography variant="h5" fontWeight="bold" color="text.primary">
                    {value}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography
                        color="text.primary"
                        sx={{
                            mt: 1,
                            fontSize: '13px',
                            color: '#6d7081',
                        }}
                    >
                        {month}
                    </Typography>
                    {showViewButton && ( // Conditionally render the "view" button
                        <Typography
                            component={RouterLink}
                            to="/allreport" // Replace with your report URL
                            sx={{
                                mt: 1,
                                fontSize: '14px',
                                textDecoration: 'none',
                                color: 'primary.main',
                                '&:hover': {
                                    textDecoration: 'underline',
                                },
                            }}
                        >
                            view
                        </Typography>
                    )}
                </Box>
            </Box>
        </Paper>
    );
};


const Home = ({ token, setToken }) => {
    const [user, setUser] = useState({});
    const [userPayments, setUserPayments] = useState([]); 
    const [totalAmount, setTotalAmount] = useState(0);
    const [monthlyTotal, setMonthlyTotal] = useState(0);
    const [annualTotal, setAnnualTotal] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalDamages, setTotalDamages] = useState(0);
    const [totalBanks, setTotalBanks] = useState(0);
    const [totalProfits, setTotalProfits] = useState(0);
    const [users, setUsers] = useState([]);
    const [userCount, setUserCount] = useState(0); 
    const [activeUserCount, setActiveUserCount] = useState(0);
    const navigate = useNavigate();

    const handleMonthlyTotalChange = (amount) => {
        setMonthlyTotal(amount);
    };

    const handleAnnualTotalChange = (amount) => {
        setAnnualTotal(amount);
    };
    

    const handleTotalExpensesChange = (amount) => {
        setTotalExpenses(amount);
    };

    const handleTotalDamagesChange = (amount) => {
        setTotalDamages(amount);
    };


    const handleTotalBanksChange = (amount) => {
        setTotalBanks(amount);
    }

    const handleTotalProfitsChange = (amount) => {
        setTotalProfits(amount);
    }


    const handleTotalAmountChange = (data) => {
        {/**console.log('Total amount data:', data);*/}
    };

    {/**useEffect(() => {
        const storedToken = token || localStorage.getItem('token');

        if (!storedToken) {
            alert('No token found, please log in.');
            return;
        }

        const fetchProfile = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${storedToken}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);*/}


    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    const [greeting, setGreeting] = useState('');
    const [showHello, setShowHello] = useState(false);

    useEffect(() => {
        const currentHour = new Date().getHours();

        if (currentHour < 12) {
            setGreeting('Good Morning');
        } else if (currentHour < 18) {
            setGreeting('Good Afternoon');
        } else {
            setGreeting('Good Evening');
        }

        const greetingTimer = setTimeout(() => {
            setGreeting('');
            setShowHello(true);
        }, 10000);

        return () => clearTimeout(greetingTimer);
    }, []);

    const calculateProfit = (annualTotal, totalExpenses, totalDamages) => {
        const total = parseFloat(annualTotal.toFixed(2)) - parseFloat(totalExpenses.toFixed(2)) - parseFloat(totalDamages.toFixed(2));

        return `Lkr. ${total.toFixed(2)}`;
    };
    
    useEffect(() => {
        axios
          .get("https://admin.bizstor.lk/public/api/users")
          .then((response) => {
            setUsers(response.data); 
            setUserCount(response.data.length - 1); 
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
          });
      }, []);

      useEffect(() => {
        axios
          .get("https://admin.bizstor.lk/public/api/users")
          .then((response) => {
            const allUsers = response.data;
            setUsers(allUsers); 
            const activeUsers = allUsers.filter(user => user.status === 'active'); 
            setActiveUserCount(activeUsers.length - 1); 
          })
          .catch((error) => {
            console.error("Error fetching users:", error);
          });
      }, []);

       useEffect(() => {
        axios
            .get("https://admin.bizstor.lk/public/api/get-user-payment")
            .then((response) => {
                if (Array.isArray(response.data.userpayment)) {
                    const payments = response.data.userpayment;
                    setUserPayments(payments); 

                    const totalAmount = payments.reduce((total, payment) => {
                        return total + Number(payment.amount); 
                    }, 0);

                    setTotalAmount(totalAmount); 
                } else {
                    console.error("Expected an array for userPayments");
                }
            })
            .catch((error) => {
                console.error("Error fetching user payments:", error);
            })
            .finally(() => {
            });
    }, []);

    function getCurrentMonth(format = 'long') {
        const date = new Date();
    
        if (format === 'long') {
            // Return the full month name
            return date.toLocaleString('default', { month: 'long' });
        } else if (format === 'short') {
            // Return the short month name
            return date.toLocaleString('default', { month: 'short' });
        } else if (format === 'number') {
            // Return the month as a number (1-12)
            return date.getMonth() + 1; // getMonth() is zero-based
        } else {
            throw new Error("Invalid format. Use 'long', 'short', or 'number'.");
        }
    }
    

    
    return (
        <Box>
            {greeting && (
                <AnimatedTypography
                    component="h1"
                    variant="h5"
                    sx={{
                        ml: 3,
                        mt: 3,
                        fontSize: '2rem',
                        color: 'orange',
                        fontWeight: 'bold',
                        letterSpacing: '0.01em',
                    }}
                >
                    {greeting} {user.name} !
                </AnimatedTypography>
            )}

            {showHello && (
                <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Dashboard
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        
                    </Typography>
                </Box>
            </AnimatedTypography>
                
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                        marginTop: '10px',
                      },
                    mb:2
                }}
            >
                <Grid container spacing={2} justifyContent="center">
                    <Monthlyincome onTotalAmountChange={handleMonthlyTotalChange} />
                    <Anualincome onTotalAmountChange={handleAnnualTotalChange} />
                    <Expenses onTotalExpensesChange={handleTotalExpensesChange} />
                    <Damages onTotalDamagesChange={handleTotalDamagesChange} />
                    <Bank onBalanceChange={handleTotalBanksChange} />
                    <Profit onProfitChange={handleTotalProfitsChange} />


                    {/* Earnings Cards */}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Total Sales"
                            value={`Lkr. ${monthlyTotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={AttachMoney}
                            color="primary.main"
                            month={`In ${getCurrentMonth('long')}`}
                            orders=""
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Profit"
                            value={`Lkr. ${(totalProfits || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={ShowChartIcon}
                            color="success.main"
                            month={`In ${getCurrentMonth('long')}`}
                            orders=""
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Expenses"
                            value={`Lkr. ${Number(totalExpenses).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={MoneyOff}
                            color="error.main"
                            month=""
                            orders="  "
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Bank"
                            value={`Lkr. ${((annualTotal + totalBanks - totalExpenses).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","))}`}
                            icon={AccountBalance}
                            color="secondary.main"
                            month="Current Balance"
                            orders=""
                            showViewButton={false}
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="All Users"
                            value={userCount}
                            icon={PeopleIcon}
                            color="secondary.main"
                            showViewButton={false}
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Active Users"
                            value={activeUserCount}
                            icon={PersonIcon}
                            color="success.main"
                            showViewButton={false}
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Deactivate Users"
                            value={userCount - activeUserCount}
                            icon={PersonIcon}
                            color="error.main"
                            showViewButton={false}
                        />
                    </Grid>)}
                    {user.email === 'bizstoradmin@gmail.com' && (
                    <Grid item xs={12} md={3}>
                        <Card
                            title="Total Payment"
                            value={`Lkr. ${totalAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                            icon={AttachMoney}
                            color="primary.main"
                            showViewButton={false}
                        />
                    </Grid>)}
                    {user.email !== 'bizstoradmin@gmail.com' && (
                    <Grid container spacing={2} sx={{ mt: 4 }}>
                        <Grid item xs={12} md={4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '90%',
                                    p: 2,
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 1,
                                    ml: 5,
                                    mr: 3,
                                    '@media (max-width: 600px)': {
                                        height: '130px',
                            },

                                }}
                            >
                                <Typography variant="h6"
                                sx={{
                                    color:'#6d7081'
                                }}
                                >
                                    <b>Shortcut</b>
                                </Typography>
                                <Box display="flex" alignItems="center" mt={2}
                                sx={{
                                    '@media (max-width: 600px)': {
                              marginTop: '42px',
                            },
                            borderRadius: 1,
                                }}
                                >

                                    <Invoice />
                                    <Return/>
                                    <Expenses2/>

                                    {/*<Link component={RouterLink} to="/reports" underline="none">
                                    <IconButton>
                                        <Assessment fontSize="large" />
                                    </IconButton>
                                </Link>*/}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box
                                sx={{
                                    p: 2,
                                    bgcolor: 'background.paper',
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 1,
                                    height: '90%',
                                    ml: 5,
                                    mr: 3,

                                }}
                            >
                                <Box mt={2} >
                                    <Chart3 onTotalAmountChange={handleTotalAmountChange} />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>)}
                </Grid>
                <Grid>
                {user.email !== 'bizstoradmin@gmail.com' && (
            <Stock />
            )}
            </Grid>
            <Grid>
                {user.email === 'bizstoradmin@gmail.com' && (
            <Barchart />
            )}
            </Grid>
            <ScrollToTopButton/>
            </Box>
            {/**{user.email !== 'bizstoradmin@gmail.com' && (
            <Stock />
            )} */}
        </Box>
    );
};

export default Home;
