import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Box,
    Typography,
    Table,
    TableBody,
    MenuItem,
    Select,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    InputAdornment,
    TablePagination,
    Avatar,
    IconButton
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import { printData } from '../Reports/Print/printData';
import { styled, keyframes } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const OrderHistory = ({ orderId }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const { fullname } = useParams();
    const [orders, setOrders] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [payments, setPayments] = useState([]);
    const [orderpayments, setOrderPayments] = useState([]);
    const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
    const [totalOrderPayAmount, setTotalOrderPayAmount] = useState(0);
    const [paymentIds, setPaymentIds] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const navigate = useNavigate();


    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
    };


    {/**useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);*/}


    useEffect(() => {
        const fetchProfile = async () => {
          const token = localStorage.getItem("token");
    
          if (!token) {
            navigate("/"); // Redirect if no token
            return;
          }
    
          try {
            const response = await axios.get("https://admin.bizstor.lk/public/api/profile", {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUser(response.data);
          } catch (error) {
            if (error.response && error.response.status === 401) {
              localStorage.removeItem("token");
              navigate("/"); // Redirect on 401 error
            }
          }
        };
    
        fetchProfile();
      }, []);

    useEffect(() => {
        if (user.id) {
            fetchOrdersAndCustomers();
            fetchPaymentData();
        }
    }, [user.id]);



    const fetchOrdersAndCustomers = async () => {
        setLoading(true);
        try {
            const [ordersResponse, customersResponse] = await Promise.all([
                axios.get('https://admin.bizstor.lk/public/api/get-data'),
                axios.get('https://admin.bizstor.lk/public/api/get-customer-data')
            ]);

            const userId = user.id; // Ensure user.id is defined

            // Initialize the Orders variable
            let Orders = [];

            if (userId) {

                // Filter orders based on userId
                Orders = ordersResponse.data.filter(order =>
                    order.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                // If userId is not available, use all orders
                Orders = ordersResponse.data;
            }

            let Customer = [];

            if (userId) {


                // Filter orders based on userId
                Customer = customersResponse.data.customers.filter(customer =>
                    customer.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                // If userId is not available, use all orders
                Customer = customersResponse.data.customers;
            }


            // Filter orders based on the provided fullname
            const filteredOrders = Orders.filter(order => order.fullname === fullname);
            setOrders(filteredOrders);
            setFilteredData(filteredOrders);

            // Find customer information
            const matchedCustomer = Customer.find(customer => customer.firstname === fullname);
            if (matchedCustomer) {
                setCustomerInfo({
                    address: matchedCustomer.address,
                    image: matchedCustomer.image
                });
            } else {
                setCustomerInfo(null);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrdersAndCustomers();
    }, [fullname]);

    const calculateTotals = (data) => {
        let totalQuantity = 0;
        let totalAmount = 0;

        data.forEach(order => {
            let orderTotal = 0;
            order.items.forEach(item => {
                totalQuantity += item.quantity;
                const itemTax = (Number(item.price) * (item.tax / 100)) || 0; // Calculate tax
                const itemTotal = Number(item.price) + itemTax;
                orderTotal += itemTotal;
            });

            const discountPercentage = order.discount || 0; // Default to 0 if undefined or null
            const discountAmount = (orderTotal * discountPercentage) / 100; // Calculate discount amount
            const finalAmount = orderTotal - discountAmount; // Apply discount to the order total
            totalAmount += finalAmount;
        });

        return { totalQuantity, totalAmount };
    };

    const { totalQuantity, totalAmount } = calculateTotals(filteredData);

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value, selectedMonth);
    };

    const handleMonthChange = (event) => {
        const month = event.target.value;
        setSelectedMonth(month);
        filterData(searchTerm, month);
    };

    const filterData = (term, month) => {
        const filtered = orders.filter(order => {
            const orderDate = new Date(order.invoicedate);
            const orderMonth = orderDate.toLocaleString('default', { month: 'long' }); // Get month name

            return (order.id.toString().includes(term) ||
                order.invoicedate.toLowerCase().includes(term)) &&
                (!month || orderMonth === month);
        });
        setFilteredData(filtered);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handlePrint = () => {

        const printDataParams = {
            filteredData,
            fullname,
            totalQuantity,
            totalAmount,
            selectedMonth
        };
        printData(printDataParams);
    };



    useEffect(() => {
        // Extract order.id values into paymentIds array
        const ids = orders.map(order => order.id);
        setPaymentIds(ids);
    }, [orders]);

    const fetchPaymentData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-payment-data');
            
            if (user.id) {
                // Filter payments based on user token
                const userPayments = response.data.filter(payment =>
                    payment.usertoken === user.id.toString()
                );
                setOrderPayments(userPayments); // This will trigger the useEffect
            }
        } catch (error) {
            console.error('Error fetching payment data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Only run the filtering when orderpayments or paymentIds change
        const filteredPayments = orderpayments.filter(payment =>
            paymentIds.includes(payment.payment_id)
        );
    
        setPayments(filteredPayments);
    
        // Calculate the total amount
        const total = filteredPayments.reduce((acc, payment) => acc + Number(payment.amount), 0);
        setTotalPaymentAmount(total);
    }, [orderpayments, paymentIds]);
    

    useEffect(() => {
        if (paymentIds.length > 0) {
            fetchPaymentData();
        }
    }, [paymentIds]);


    const calculateTotalPayAmount = (orders) => {
        return orders.reduce((total, order) => {
            return total + Number(order.payamount);
        }, 0);
    };

    const totalPayAmount = calculateTotalPayAmount(orders);

    const totalFinalAmount = filteredData.reduce((acc, order) => {
        if (order.term !== 'due on receipt') {
            order.items.forEach(item => {
                const itemTax = (Number(item.price) * (item.tax / 100)) || 0;
                const itemTotal = Number(item.price) + itemTax;
                const discountPercentage = order.discount || 0;
                const discountAmount = (itemTotal * discountPercentage) / 100;
                const finalAmount = itemTotal - discountAmount;
                acc += finalAmount;
            });
        }
        return acc;
    }, 0);

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Order History for {fullname}
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Report - Customers - {fullname}
                    </Typography>
                </Box>
            </AnimatedTypography>

            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Report / Customer / {fullname}
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    //boxShadow: 1,
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'white',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h4" component="h1" gutterBottom>

                </Typography>
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                             textTransform:'uppercase',
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                        }}
                    >
                        <h2>ORDER HISTORY - {fullname}</h2>
                    </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', mb: 2 }}>
                <Box
                        sx={{
                            mt: 2,                          
                            '@media (max-width: 600px)': {
                                display: { xs: 'none', },
                                      },
                        }}
                        >
                            <IconButton onClick={handlePrint}>
                            <PrintIcon />
                        </IconButton>
                        <IconButton onClick={toggleSearch}>
                    <SearchIcon />
                </IconButton>
                        </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            marginLeft:'20px' ,
                            flexDirection: { xs: 'column', sm: 'row' },
                            '@media (max-width: 600px)': {
                                margin: 'auto',
                                gap: 1, 
                                      },
                        }}
                    >
                        
                        <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: isSearchOpen ? 1 : 0, height: isSearchOpen ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden',  }}
            ><Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2, 
                flexDirection: { xs: 'column', sm: 'row' },
                '@media (max-width: 600px)': {
                    margin: 'auto',
                    gap: 1, 
                          },
            }}
            >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            size="small"
                            type="date"
                            placeholder="Search…"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ width: '250px' }}
                        />
                        <Select
                            value={selectedMonth}
                            onChange={handleMonthChange}
                            displayEmpty
                            size="small"
                            sx={{
                                width: '250px',
                                height: '40px',
                                mt: 2,
                            }}
                        >
                            <MenuItem value="">
                                <em>All Months</em>
                            </MenuItem>
                            <MenuItem value="January">January</MenuItem>
                            <MenuItem value="February">February</MenuItem>
                            <MenuItem value="March">March</MenuItem>
                            <MenuItem value="April">April</MenuItem>
                            <MenuItem value="May">May</MenuItem>
                            <MenuItem value="June">June</MenuItem>
                            <MenuItem value="July">July</MenuItem>
                            <MenuItem value="August">August</MenuItem>
                            <MenuItem value="September">September</MenuItem>
                            <MenuItem value="October">October</MenuItem>
                            <MenuItem value="November">November</MenuItem>
                            <MenuItem value="December">December</MenuItem>
                        </Select>
                        </Box>
                        </motion.div>
                    </Box>

                    <Box display="flex" flexWrap="wrap" alignItems="center" sx={{ ml: 'auto' }}>
                    <Box
                        sx={{
                            mt: 2,
                            display: { sm: 'none', xs:'flex' },
                            '@media (max-width: 600px)': {
                                display: { xs:'flex' },
                                      },
                        }}
                        >
                            <IconButton onClick={handlePrint}>
                            <PrintIcon />
                        </IconButton>
                        <IconButton onClick={toggleSearch}>
                    <SearchIcon />
                </IconButton>
                        </Box>
                        {customerInfo && (
                            <Box sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                                {customerInfo.image ? (
                                    <Avatar
                                        src={`https://admin.bizstor.lk/storage/app/public/${customerInfo.image}`}
                                        alt={fullname}
                                        sx={{ width: 60, height: 60, borderRadius: '50%' }}
                                    />
                                ) : (
                                    <Avatar
                                        sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: 'grey' }}
                                    />
                                )}
                            </Box>
                        )}
                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                            <Typography variant="h6"><b>Sales</b></Typography>
                            <Typography><strong>TOTAL:</strong> Lkr.{Number(totalAmount) ? Number(totalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'}</Typography>
                            <Typography><strong>TO BE PAID:</strong> Lkr.{Number(totalFinalAmount) ? Number(totalFinalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0.00'}</Typography>
                            <Typography><strong>PAID:</strong> Lkr.{(totalPaymentAmount + totalPayAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                            <Typography><strong>CREDIT:</strong> Lkr.{(totalFinalAmount - totalPaymentAmount - totalPayAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                        </Box>
                    </Box>
                </Box>

                {loading ? (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                ) : (
                    <Box>
                        <TableContainer
                            
                            sx={{
                                borderRadius: '7px',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                overflowX: 'auto', // Enable horizontal scrolling
                                maxWidth: '100%',  // Ensure responsiveness
                            }}
                        >
                            <Table stickyHeader sx={{ minWidth: 1000 }}> {/* Set a minimum width for the table */}
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Product</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Qty</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Rate</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Price</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Tax</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Discount</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>
                                            Total (After Include Discount & Tax)
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', fontSize: '15px', padding: '12px' }}>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData
                                        .sort((a, b) => new Date(b.invoicedate) - new Date(a.invoicedate)) // Sort by date, latest first
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(order =>
                                            order.items.map((item, index) => {
                                                const itemTax = (Number(item.price) * (item.tax / 100)) || 0;
                                                const itemTotal = Number(item.price) + itemTax;
                                                const discountPercentage = order.discount || 0;
                                                const discountAmount = (itemTotal * discountPercentage) / 100;
                                                const finalAmount = itemTotal - discountAmount;

                                                return (
                                                    <TableRow
                                                        key={`${order.id}-${index}`}
                                                        sx={{
                                                            bgcolor: index % 2 === 0 ? 'white' : '#ffffff', // Alternating row colors
                                                            '&:hover': { bgcolor: '#f9f9f9' }, // Hover effect
                                                            transition: 'background-color 0.3s ease-in-out', // Smooth transition on hover
                                                        }}
                                                    >
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>{item.product}</TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>{item.quantity}</TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>Rs.{item.rate}</TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>
                                                            Rs.{Number(item.price) ? Number(item.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A'}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>
                                                            {item.tax !== null && item.tax !== undefined ? `${item.tax}%` : 'N/A'}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>
                                                            {discountPercentage !== null && discountPercentage !== undefined ? `${discountPercentage}%` : 'N/A'}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>
                                                            Rs.{Number(finalAmount) !== null && Number(finalAmount) !== undefined ? Number(finalAmount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'N/A'}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: '10px', fontSize: '14px', color: '#636363' }}>
                                                            {new Date(order.invoicedate).toLocaleDateString()}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>



                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Box
                        display="flex"
                        justifyContent="center"
                        width="100%"
                        mt={2}
                    >
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
            <ScrollToTopButton />
        </Box>
    );
};

export default OrderHistory;
